 
import { ReduxInterface } from 'Editor/services';
import ActionContext from 'Editor/services/EditionManager/EditionModes/_Common/models/ActionContext';
import { ParseMapper } from 'Editor/services/Parsers';
import EditorManager from 'Editor/services/EditorManager';
import StylesUtils from 'Editor/services/Styles/Utils/StylesUtils';
import { Logger } from '_common/services';

import { notify } from '_common/components/ToastSystem';
import deepEqual from 'deep-equal';
import { EditorDOMUtils, EditorDOMElements } from 'Editor/services/_Common/DOM';
import { EditorSelectionUtils, EditorRange } from 'Editor/services/_Common/Selection';
import DocumentStylesManager from 'Editor/services/DataManager/controllers/Styles/DocumentStylesManager';
import { ParagraphElement } from 'Editor/services/VisualizerManager/Views/ViewBuilders/';
import { MergedStyleData } from 'Editor/services/DataManager/controllers/Styles/DocumentStyle';
import DocumentStyle from 'Editor/services/DataManager/controllers/Styles/DocumentStyle';
import {
  DocumentStyleData,
  DocumentStyleProperties,
} from 'Editor/services/DataManager/models/Structure/Structure.types';

type ComputedStyles = {
  [StylesUtils.STYLES.ALIGNMENT]: 'left' | 'center' | 'right' | 'justify';
  [StylesUtils.STYLES.ASA]: boolean;
  [StylesUtils.STYLES.ASB]: boolean;
  [StylesUtils.STYLES.BOLD]: boolean;
  [StylesUtils.STYLES.COLOR]: string | boolean;
  [StylesUtils.STYLES.CTS]: boolean;
  [StylesUtils.STYLES.FONTFAMILY]: string;
  [StylesUtils.STYLES.FONTSIZE]: number;
  [StylesUtils.STYLES.HIGHLIGHTCOLOR]: string;
  [StylesUtils.STYLES.ITALIC]: boolean;
  [StylesUtils.STYLES.KL]: boolean;
  [StylesUtils.STYLES.KN]: boolean;
  [StylesUtils.STYLES.LEFTINDENTATION]: string | undefined;
  [StylesUtils.STYLES.LINEHEIGHT]: number;
  [StylesUtils.STYLES.LISTID]: string | undefined;
  [StylesUtils.STYLES.LISTLEVEL]: number | undefined;
  [StylesUtils.STYLES.OTL]: number | undefined;
  [StylesUtils.STYLES.PARAGRAPHCOLOR]: string;
  [StylesUtils.STYLES.PBB]: boolean;
  [StylesUtils.STYLES.RIGHTINDENTATION]: string | undefined;
  [StylesUtils.STYLES.SPACEAFTER]: number;
  [StylesUtils.STYLES.SPACEBEFORE]: number;
  [StylesUtils.STYLES.SPECIALINDENT]: 'f' | 'h' | undefined;
  [StylesUtils.STYLES.SPECIALINDENTVALUE]: number | undefined;
  [StylesUtils.STYLES.UNDERLINE]: boolean;
  [StylesUtils.STYLES.VANISH]: boolean;
  [StylesUtils.STYLES.WC]: boolean;
};

type MappedStyles = {
  a: 'l' | 'c' | 'r' | 'j'; // differs from DocumentStyleProperties
  asa: boolean;
  asb: boolean;
  bg: string | false; // differs from DocumentStyleProperties
  bold: boolean;
  color: string | boolean;
  cts: boolean;
  fontfamily: string;
  fontsize: number;
  ind: { l?: string; r?: string }; // differs from DocumentStyleProperties
  italic: boolean;
  kl: boolean;
  kn: boolean;
  lh: number;
  lst: {
    lId: string;
    lLv: number | string;
  };
  otl: number | undefined;
  pbb: boolean;
  sa: number;
  sb: number;
  sp_ind: { t: 'f' | 'h'; v: number }; // differs from DocumentStyleProperties
  underline: boolean;
  v: boolean;
  wc: boolean;
};

type StyleStatus = {
  id: string;
  changed: boolean;
  p: Partial<MappedStyles>;
};

const backgroundColorMapper = (
  mappedStyles: Partial<MappedStyles>,
  computedStyles: Partial<ComputedStyles>,
) => {
  let backgroundColor: MappedStyles['bg'];
  if (
    computedStyles.highlightColor &&
    computedStyles.highlightColor !== 'rgba(0, 0, 0, 0)' &&
    computedStyles.highlightColor !== 'transparent'
  ) {
    backgroundColor = computedStyles.highlightColor;
  } else if (
    computedStyles.paragraphColor &&
    computedStyles.paragraphColor !== 'rgba(0, 0, 0, 0)' &&
    computedStyles.paragraphColor !== 'transparent'
  ) {
    backgroundColor = computedStyles.paragraphColor;
  } else {
    backgroundColor = false;
  }

  if (backgroundColor != null) {
    if (backgroundColor !== false) {
      if (backgroundColor.includes('rgb')) {
        const hex = EditorDOMUtils.rgbToHex(backgroundColor);
        if (hex) {
          backgroundColor = hex.substring(1, backgroundColor.length);
        }
      } else if (backgroundColor.includes('#')) {
        backgroundColor = backgroundColor.substring(1, backgroundColor.length);
      }
    }

    mappedStyles.bg = backgroundColor;
  }
};

const documentStylesMapper: {
  [index: string]: (
    mappedStyles: Partial<MappedStyles>,
    computedStyles: Partial<ComputedStyles>,
  ) => void;
} = {
  [StylesUtils.STYLES.FONTFAMILY]: (
    mappedStyles: Partial<MappedStyles>,
    computedStyles: Partial<ComputedStyles>,
  ) => {
    mappedStyles.fontfamily = computedStyles.fontFamily;
  },
  [StylesUtils.STYLES.FONTSIZE]: (
    mappedStyles: Partial<MappedStyles>,
    computedStyles: Partial<ComputedStyles>,
  ) => {
    mappedStyles.fontsize = computedStyles.fontSize;
  },
  [StylesUtils.STYLES.COLOR]: (
    mappedStyles: Partial<MappedStyles>,
    computedStyles: Partial<ComputedStyles>,
  ) => {
    const color = computedStyles.color;

    // TODO: convert color to hexa along with format elements minimization
    // if (color) {
    //   if (color.includes('rgb')) {
    //     const hex = DOMUtils.rgbToHex(color);
    //     color = hex.substring(1, color.length);
    //   } else if (color.includes('#')) {
    //     color = color.substring(1, color.length);
    //   }

    mappedStyles.color = color;
    // }
  },
  [StylesUtils.STYLES.HIGHLIGHTCOLOR]: backgroundColorMapper,
  [StylesUtils.STYLES.PARAGRAPHCOLOR]: backgroundColorMapper,
  [StylesUtils.STYLES.BOLD]: (
    mappedStyles: Partial<MappedStyles>,
    computedStyles: Partial<ComputedStyles>,
  ) => {
    mappedStyles.bold = computedStyles.bold;
  },
  [StylesUtils.STYLES.ITALIC]: (
    mappedStyles: Partial<MappedStyles>,
    computedStyles: Partial<ComputedStyles>,
  ) => {
    mappedStyles.italic = computedStyles.italic;
  },
  [StylesUtils.STYLES.UNDERLINE]: (
    mappedStyles: Partial<MappedStyles>,
    computedStyles: Partial<ComputedStyles>,
  ) => {
    mappedStyles.underline = computedStyles.underline;
  },
  [StylesUtils.STYLES.ALIGNMENT]: (
    mappedStyles: Partial<MappedStyles>,
    computedStyles: Partial<ComputedStyles>,
  ) => {
    mappedStyles.a = ParseMapper.alignmentMapper.parse(computedStyles.alignment);
  },
  [StylesUtils.STYLES.LINEHEIGHT]: (
    mappedStyles: Partial<MappedStyles>,
    computedStyles: Partial<ComputedStyles>,
  ) => {
    mappedStyles.lh = computedStyles.lineHeight;
  },
  [StylesUtils.STYLES.SPACEBEFORE]: (
    mappedStyles: Partial<MappedStyles>,
    computedStyles: Partial<ComputedStyles>,
  ) => {
    mappedStyles.sb = computedStyles.spaceBefore;
  },
  [StylesUtils.STYLES.ASB]: (
    mappedStyles: Partial<MappedStyles>,
    computedStyles: Partial<ComputedStyles>,
  ) => {
    mappedStyles.asb = computedStyles.asb;
  },
  [StylesUtils.STYLES.SPACEAFTER]: (
    mappedStyles: Partial<MappedStyles>,
    computedStyles: Partial<ComputedStyles>,
  ) => {
    mappedStyles.sa = computedStyles.spaceAfter;
  },
  [StylesUtils.STYLES.ASA]: (
    mappedStyles: Partial<MappedStyles>,
    computedStyles: Partial<ComputedStyles>,
  ) => {
    mappedStyles.asa = computedStyles.asa;
  },
  [StylesUtils.STYLES.LEFTINDENTATION]: (
    mappedStyles: Partial<MappedStyles>,
    computedStyles: Partial<ComputedStyles>,
  ) => {
    if (computedStyles.leftIndentation != null) {
      let ind = {
        ...mappedStyles.ind,
      };

      ind.l = computedStyles.leftIndentation;

      mappedStyles.ind = ind;
    }
  },
  [StylesUtils.STYLES.RIGHTINDENTATION]: (
    mappedStyles: Partial<MappedStyles>,
    computedStyles: Partial<ComputedStyles>,
  ) => {
    if (computedStyles.rightIndentation != null) {
      let ind = {
        ...mappedStyles.ind,
      };

      ind.r = computedStyles.rightIndentation;

      mappedStyles.ind = ind;
    }
  },
  [StylesUtils.STYLES.SPECIALINDENT]: (
    mappedStyles: Partial<MappedStyles>,
    computedStyles: Partial<ComputedStyles>,
  ) => {
    if (computedStyles.specialIndent != null) {
      mappedStyles.sp_ind = {
        t: computedStyles.specialIndent,
        v: computedStyles.specialIndentValue || 0,
      };
    }
  },
  [StylesUtils.STYLES.VANISH]: (
    mappedStyles: Partial<MappedStyles>,
    computedStyles: Partial<ComputedStyles>,
  ) => {
    mappedStyles.v = computedStyles.vanish;
  },
  [StylesUtils.STYLES.LISTID]: (
    mappedStyles: Partial<MappedStyles>,
    computedStyles: Partial<ComputedStyles>,
  ) => {
    // intended diff with undefined
    if (computedStyles.listId !== undefined) {
      if (computedStyles.listLevel !== undefined) {
        mappedStyles.lst = {
          lId: computedStyles.listId,
          lLv: computedStyles.listLevel,
        };
      }
    }
  },
  [StylesUtils.STYLES.WC]: (
    mappedStyles: Partial<MappedStyles>,
    computedStyles: Partial<ComputedStyles>,
  ) => {
    mappedStyles.wc = computedStyles.wc;
  },
  [StylesUtils.STYLES.KN]: (
    mappedStyles: Partial<MappedStyles>,
    computedStyles: Partial<ComputedStyles>,
  ) => {
    mappedStyles.kn = computedStyles.kn;
  },
  [StylesUtils.STYLES.KL]: (
    mappedStyles: Partial<MappedStyles>,
    computedStyles: Partial<ComputedStyles>,
  ) => {
    mappedStyles.kl = computedStyles.kl;
  },
  [StylesUtils.STYLES.CTS]: (
    mappedStyles: Partial<MappedStyles>,
    computedStyles: Partial<ComputedStyles>,
  ) => {
    mappedStyles.cts = computedStyles.cts;
  },
  [StylesUtils.STYLES.PBB]: (
    mappedStyles: Partial<MappedStyles>,
    computedStyles: Partial<ComputedStyles>,
  ) => {
    mappedStyles.pbb = computedStyles.pbb;
  },
  [StylesUtils.STYLES.OTL]: (mappedStyles, computedStyles) => {
    mappedStyles.otl = computedStyles.otl;
  },
};

/**
 * Style object data
 *
 * id ID of the style
 * b  If its a base style, that is, a default style
 * n  The name of the style
 * t  The type of the element it applies to
 * p  The paragraph styles definitions
 *  a                 Alignment
 *  bg                Background color
 *  color             Text color
 *  fontfamily        Font family
 *  fontsize          Font size
 *  italic            Italic
 *  lh                Line height
 *  sa                Space after
 *  sb                Space before
 *  underline         Underline
 *  bold              Bold
 */

export class DocumentStyles {
  private editorContext: Editor.Context;
  private stylesContext: Editor.Styles.Context;

  private documentStyles: DocumentStylesManager | undefined;
  private timeouts: { [name: string]: NodeJS.Timeout };

  constructor(editorContext: Editor.Context, stylesContext: Editor.Styles.Context) {
    this.editorContext = editorContext;
    this.stylesContext = stylesContext;
    this.timeouts = {};

    this.handleResponseDeleteDocumentStyle = this.handleResponseDeleteDocumentStyle.bind(this);
    this.handleResponseResetTemplateStyle = this.handleResponseResetTemplateStyle.bind(this);
  }

  start() {
    this.documentStyles = this.editorContext.DataManager?.styles.documentStyles;
    this.editorContext.DataManager?.on(
      'DOCUMENT_STYLES_DELETE_RESPONSE',
      this.handleResponseDeleteDocumentStyle,
    );
    this.editorContext.DataManager?.on(
      'DOCUMENT_STYLES_RESET_RESPONSE',
      this.handleResponseResetTemplateStyle,
    );

    this.initialize();
  }

  private initialize() {}

  destroy() {
    this.editorContext.DataManager?.off(
      'DOCUMENT_STYLES_DELETE_RESPONSE',
      this.handleResponseDeleteDocumentStyle,
    );
    this.editorContext.DataManager?.off(
      'DOCUMENT_STYLES_RESET_RESPONSE',
      this.handleResponseResetTemplateStyle,
    );
  }

  private getStyleStatusFromSelection(): StyleStatus | null {
    // get selected style from nodes
    if (!this.documentStyles?.loaded) {
      return null;
    }

    let selectedStyle: StyleStatus | null = null;
    if (this.editorContext.selectionManager) {
      const nodesList = EditorSelectionUtils.getElementsFromRange();
      for (let i = 0; i < nodesList.length; i++) {
        const nodeToCheck = nodesList[i];

        if (nodeToCheck instanceof ParagraphElement) {
          const styleId = nodeToCheck.styleId;
          if (styleId) {
            const style = this.documentStyles.style(styleId);

            if (selectedStyle === null && style) {
              selectedStyle = {
                id: style.id,
                changed: false,
                p: {},
              };
              break;
            }
          }
        }
      }

      if (selectedStyle) {
        const stylableNodesList = this.getStylableElementsFromSelection(
          EditorSelectionUtils.getRange(),
        );

        const mappedStyles: Partial<MappedStyles> = {};
        if (stylableNodesList) {
          const formatStyles = StylesUtils.getStylesAppliedToNode(
            stylableNodesList[0],
            StylesUtils.DOCUMENT_STYLES_LIST,
            {
              dataManager: this.editorContext.DataManager,
            },
          );

          Object.keys(formatStyles).forEach((key) => {
            if (documentStylesMapper[key]) {
              //@ts-expect-error Editor.Styles.StylesApplied !== ComputedStyles
              documentStylesMapper[key](mappedStyles, formatStyles);
            }
          });
        }

        const documentStyle = this.documentStyles.style(selectedStyle.id);
        if (documentStyle) {
          const styleKeys = Object.keys(mappedStyles) as (keyof MappedStyles)[];

          const checkedStyles: Partial<MappedStyles> = {};
          for (let z = 0; z < styleKeys.length; z++) {
            const styleKey = styleKeys[z];
            let changed = false;

            let value = documentStyle.extendedP?.[styleKey] as MappedStyles[keyof MappedStyles];

            if (value != null && styleKey === 'fontsize' && value !== '' && !Number.isNaN(+value)) {
              value = +value;
            }

            if (typeof value === 'object') {
              const valuesEqual = deepEqual(value, mappedStyles[styleKey], { strict: false });
              if (!valuesEqual) {
                changed = true;
              }
            } else if (value != mappedStyles[styleKey]) {
              changed = true;
            }

            if (checkedStyles[styleKey] == null) {
              if (changed) {
                selectedStyle.changed = true;
                //@ts-expect-error
                selectedStyle.p[styleKey] = mappedStyles[styleKey];
              }
              //@ts-expect-error
              checkedStyles[styleKey] = mappedStyles[styleKey];
            } else if (checkedStyles[styleKey] !== mappedStyles[styleKey]) {
              delete selectedStyle.p[styleKey];
              if (Object.keys(selectedStyle.p).length === 0) {
                selectedStyle.changed = false;
              }
              //@ts-expect-error
              checkedStyles[styleKey] = '';
            }
          }
        }
      }
    }
    return selectedStyle;
  }

  checkDocumentStyleStatusOnSelection() {
    // update values on redux
    this.scheduleSetSelectedDocumentStyle(this.getStyleStatusFromSelection());
  }

  private scheduleSetSelectedDocumentStyle(selectedStyle?: StyleStatus | null) {
    if (selectedStyle) {
      if (this.timeouts.setSelectedDocumentStyle) {
        clearTimeout(this.timeouts.setSelectedDocumentStyle);
      }

      this.timeouts.setSelectedDocumentStyle = setTimeout(() => {
        // @ts-expect-error left alignment is not being recognised
        ReduxInterface.setSelectedDocumentStyle(selectedStyle);
      }, 100);
    }
  }

  private getStylableElementsFromSelection(
    range: EditorRange | undefined = EditorSelectionUtils.getRange(),
  ) {
    const elementsToCheck = [...EditorDOMElements.BLOCK_TEXT_ELEMENTS, 'FORMAT-ELEMENT'];
    if (range) {
      const textNodes = range.getNodes([Node.TEXT_NODE]);
      const stylableNodes = Array.from(textNodes).reduce((nodesArray: HTMLElement[], node) => {
        const closest = EditorDOMUtils.closest(node, elementsToCheck);
        if (closest instanceof HTMLElement && !nodesArray.includes(closest)) {
          nodesArray.push(closest);
        }
        return nodesArray;
      }, []);

      if (stylableNodes.length === 0) {
        const closest = EditorDOMUtils.closest(range.commonAncestorContainer, elementsToCheck);
        if (closest instanceof HTMLElement) {
          stylableNodes.push(closest);
        }
      }
      return stylableNodes;
    }
  }

  private updateDocumentStyle(
    actionContext: ActionContext,
    styleId: string,
    valuesToUpdate: StyleStatus | null,
  ) {
    if (this.documentStyles) {
      if (!this.documentStyles.style(styleId)) {
        this.editorContext.DataManager?.styles.updateDocumentStyle(styleId, {
          ...valuesToUpdate,
          id: styleId,
        });
        actionContext.jsonChanges = true;
      } else {
        const style = this.documentStyles.style(styleId);
        const data = { ...style.data };
        if (valuesToUpdate) {
          if (style.templateOnly) {
            //@ts-expect-error MappedStyles["ind"] and DocumentStyleProperties["ind"] typings differ
            data.p = valuesToUpdate.p;
          } else {
            const valuesToUpdateKeys = Object.keys(valuesToUpdate) as (keyof StyleStatus)[];
            valuesToUpdateKeys.forEach((key) => {
              //@ts-expect-error
              if (typeof data[key] === 'object') {
                //@ts-expect-error
                data[key] = { ...data[key], ...valuesToUpdate[key] };
              } else {
                //@ts-expect-error
                data[key] = valuesToUpdate[key];
              }
            });
          }

          // We don't want to send this to the server
          this.editorContext.DataManager?.styles.updateDocumentStyle(styleId, data);
          actionContext.jsonChanges = true;
        }
      }
    }
  }

  getStyleByName(name: string) {
    return this.documentStyles?.getStyleByName(name);
  }

  doesStyleNameExists(name: string) {
    return this.documentStyles?.doesStyleNameExists(name);
  }

   
  private getNameAndIdForStyle(baseStyle: MergedStyleData) {
    if (baseStyle) {
      let name = baseStyle.n;
      const id = DocumentStyle.generateStyleId();

      if (baseStyle.b) {
        name = name.charAt(0).toUpperCase() + name.slice(1);
      }

      do {
        if (name.includes('(') && name.includes(')')) {
          const index = parseInt(name.substring(name.indexOf('(') + 1, name.indexOf(')')), 10);

          name = `${name.substring(0, name.indexOf('('))}(${index + 1})`;
        } else {
          name = `${name} (1)`;
        }
      } while (this.documentStyles?.doesStyleNameExists(name));

      return { name, id };
    }

    return {};
  }

  async createNewDocumentStyleFromId(baseStyleId: string) {
    EditorManager.getInstance().removePasteOptions();

    const actionContext = new ActionContext();

    const selectedStyleStatus = this.getStyleStatusFromSelection();
    try {
      const newStyleId = this.documentStyles?.createNewStyleFromStyle(
        baseStyleId,
        selectedStyleStatus,
      );
      if (newStyleId) {
        await this.applyDocumentStyleToSelection(newStyleId);
        actionContext.jsonChanges = true;
        //@ts-expect-error saveActionChanges does not exist on type 'ChangeTrackerMix'
        this.editorContext.changeTracker?.saveActionChanges(actionContext);
      }
    } catch (error) {
      Logger.captureException(error);
    }
  }

  createNewDocumentStyle(id: string, newStyle: Partial<DocumentStyleData>) {
    this.editorContext.DataManager?.styles.addNewDocumentStyle(id, newStyle);
  }

  createNewDocumentStyleFromWord({
    n,
    p,
    t,
  }: {
    n: string;
    p: DocumentStyleProperties;
    t: string;
  }) {
    //TODO: recieve action context from copy paste process
    //TODO: register jsonChanges to create a patch
    const id = DocumentStyle.generateStyleId();
    const newDocumentStyle: Partial<DocumentStyleData> = {
      p,
      n,
      id,
      e: 'p',
      b: false,
      t,
    };
    this.createNewDocumentStyle(id, newDocumentStyle);
    return newDocumentStyle;
  }

  renameStyle(styleId: string, newName: string) {
    EditorManager.getInstance().removePasteOptions();

    const actionContext = new ActionContext();

    if (this.documentStyles) {
      if (!this.documentStyles.doesStyleNameExists(newName)) {
        this.editorContext.DataManager?.styles.renameDocumentStyle(styleId, newName);
        notify({
          type: 'success',
          title: 'PARAGRAPH_STYLE_RENAMED_TITLE',
          message: 'PARAGRAPH_STYLE_RENAMED_MESSAGE',
          messageValues: { value: newName },
        });
        actionContext.jsonChanges = true;
        //@ts-expect-error saveActionChanges does not exist on type 'ChangeTrackerMix'
        this.editorContext.changeTracker?.saveActionChanges(actionContext);
      } else {
        Logger.captureMessage(`Style name already exists: ${newName}`);
      }
    }
  }

  deleteDocumentStyle(styleId: string) {
    this.editorContext.DataManager?.styles.deleteDocumentStyle(styleId);
  }

   
  private handleResponseDeleteDocumentStyle({
    success,
    styleId,
    style,
  }: {
    success: boolean;
    styleId: string;
    style: any;
  }) {
    if (success) {
      notify({
        type: 'success',
        title: 'PARAGRAPH_STYLE_REMOVED_TITLE',
        titleValues: { style: style.n },
        message: 'PARAGRAPH_STYLE_REMOVED_MESSAGE',
        messageValues: { style: style.n },
      });
    } else {
      const styleName = this.documentStyles?.style(styleId).n;
      if (styleName) {
        notify({
          type: 'error',
          title: 'PARAGRAPH_STYLE_REMOVED_TITLE_ERROR',
          titleValues: { style: styleName },
          message: 'PARAGRAPH_STYLE_REMOVED_MESSAGE_ERROR',
          messageValues: { style: styleName },
        });
      }
    }
  }

  resetTemplateStyle(styleId: string) {
    this.editorContext.DataManager?.styles.resetTemplateStyle(styleId);
  }

  private handleResponseResetTemplateStyle({
    success,
    styleId,
    style,
  }: {
    success: boolean;
    styleId: string;
    style: any;
  }) {
    if (success) {
      notify({
        type: 'success',
        title: 'PARAGRAPH_STYLE_RESETED_TITLE',
        titleValues: { style: style.n },
        message: 'PARAGRAPH_STYLE_RESETED_MESSAGE',
        messageValues: { style: style.n },
      });
    } else {
      const styleName = this.documentStyles?.style(styleId).n;
      if (styleName) {
        notify({
          type: 'success',
          title: 'PARAGRAPH_STYLE_RESETED_TITLE',
          titleValues: { style: styleName },
          message: 'PARAGRAPH_STYLE_RESETED_MESSAGE',
          messageValues: { style: styleName },
        });
      }
    }
  }

  async updateDocumentStyleFromSelection(styleId: string) {
    if (this.editorContext.navigationManager?.isMarkerRendered()) {
      this.editorContext.visualizerManager?.selection.stopSelectionTracker();

      try {
        this.editorContext.navigationManager.scrollIntoSelection();

        EditorManager.getInstance().removePasteOptions();

        const actionContext = new ActionContext();

        const selectedStyleStatus = this.getStyleStatusFromSelection();

        this.updateDocumentStyle(actionContext, styleId, selectedStyleStatus);
        // // Clear unwanted format elements
        // this.stylesContext.inlineStyles.clearFormatting({
        //   saveMarker: false,
        //   askUser: false,
        //   saveChanges: false,
        //   scrollToMarker: false,
        //   expandWord: true,
        //   cleanParagraphStyle: true,
        // });

        if (selectedStyleStatus && selectedStyleStatus.id !== styleId) {
          await this.applyDocumentStyleToSelection(styleId, actionContext);
        } else {
          this.editorContext.visualizerManager?.selection.triggerSelectionChanged();
        }

        //@ts-expect-error saveActionChanges does not exist on type 'ChangeTrackerMix'
        this.editorContext.changeTracker?.saveActionChanges(actionContext);
      } catch (error) {
        Logger.captureException(error);
      } finally {
        this.editorContext.visualizerManager?.selection.debounceStartSelectionTracker();
      }
    }
  }

  async applyDocumentStyleToBlock(
    actionContext: ActionContext,
    styleId: string,
    blockElement: HTMLElement,
    blockRange: EditorRange,
  ) {
    if (this.editorContext.documentContainer) {
      const level0 = EditorDOMUtils.findFirstLevelChildNode(
        this.editorContext.documentContainer,
        blockElement,
      );

      if (level0) {
        if (EditorDOMUtils.isClosestBlockNodeEditable(level0)) {
          if (this.documentStyles?.style(styleId)) {
            // clear format
            this.stylesContext.inlineStyles?.clearFormatting({
              rangeToHandle: blockRange,
              saveMarker: false,
              askUser: false,
              saveChanges: false,
              scrollToMarker: false,
              expandWord: false,
              cleanParagraphStyle: false,
            });

            if (EditorDOMUtils.isClosestTextElementEditable(blockElement)) {
              await this.editorContext.DataManager?.numbering.includeNodeInOutline(blockElement.id);
              actionContext.jsonChanges = true;
            }

            blockElement.dataset.styleId = styleId;
          } else {
            throw new Error(`Style does not exist! ${styleId}`);
          }
        }
        actionContext.addChangeUpdatedNode(level0);
      }
    }
  }

  async applyDocumentStyleToSelection(styleId: string, actionContext?: ActionContext) {
    if (!this.stylesContext.askUserAboutThis?.()) {
      return;
    }

    let saveChanges = false;

    if (this.editorContext.navigationManager?.isMarkerRendered()) {
      this.editorContext.visualizerManager?.selection.stopSelectionTracker();

      try {
        this.editorContext.navigationManager.scrollIntoSelection();

        EditorManager.getInstance().removePasteOptions();

        if (!actionContext) {
          actionContext = new ActionContext();
          saveChanges = true;
        }

        const stylableRanges = EditorRange.splitRangeByBlocks();
        if (stylableRanges.length) {
          for (let i = 0; i < stylableRanges.length; i++) {
            const element = stylableRanges[i].block;
            if (element instanceof HTMLElement) {
              await this.applyDocumentStyleToBlock(
                actionContext,
                styleId,
                element,
                stylableRanges[i].range,
              );
            }
          }

          this.editorContext.visualizerManager?.selection.triggerSelectionChanged();

          if (saveChanges) {
            //@ts-expect-error 'saveActionChanges' does not exist on type 'ChangeTrackerMix'
            this.editorContext.changeTracker?.saveActionChanges(actionContext);
          }
        }
      } finally {
        this.editorContext.visualizerManager?.selection.debounceStartSelectionTracker();
        setTimeout(() => {
          this.editorContext.navigationManager?.scrollIntoSelection();
        }, 0);
      }
    }
  }
}
