import './ParagraphElement.module.scss';

import 'tippy.js/index.css';

import { INDENT_TYPE, DISPLAY_TYPES } from 'Editor/services/consts';
import EditorManager from 'Editor/services/EditorManager';
import DOMElementFactory from 'Editor/services/DOMUtilities/DOMElementFactory/DOMElementFactory';
import { ELEMENTS } from 'Editor/services/consts';
import StylesUtils from 'Editor/services/Styles/Utils/StylesUtils';
import { BaseViewElement, BaseBlockElement } from '../../BaseViewBuilder';
import { EditorDOMElements, EditorDOMUtils } from 'Editor/services/_Common/DOM';

export class ParagraphElement extends BaseBlockElement {
  private timeoutLineHeightUpdate: NodeJS.Timeout | undefined;

  constructor(Visualizer?: Editor.Visualizer.State, Data?: Editor.Data.API) {
    super(Visualizer, Data);

    this.handleParagraphStyleUpdate = this.handleParagraphStyleUpdate.bind(this);
  }

  static get observedAttributes() {
    return [
      ...BaseBlockElement.observedAttributes,
      'data-style-id',
      'data-left-indentation',
      'data-right-indentation',
      'data-special-indent',
      'data-special-indent-value',
      'data-background-color',
      'data-line-height',
      'data-space-after',
      'data-space-before',
      // styles to replicate
      'data-highlight-color',
      'data-font-family',
      'data-font-size',
      'data-color',
      'data-bold',
      'data-italic',
      'data-underline',
      'data-strikethrough',
      'data-superscript',
      'data-subscript',
      'data-vanish',
      'data-asb',
      'data-asa',
    ];
  }

  connectedCallback() {
    super.connectedCallback();

    this.checkEmptyContent();

    if (
      this.Visualizer?.hooks?.afterParagraphStyleUpdate &&
      (this.hasStyleAttribute('lineHeight') ||
        this.hasStyleAttribute('fontFamily') ||
        this.hasStyleAttribute('fontSize'))
    ) {
      this.Visualizer.hooks.afterParagraphStyleUpdate?.register(this.handleParagraphStyleUpdate);
    }
  }

  preRender(): void {
    // this.handleDataAttributes();
    if (!this.dataset.styleId) {
      this.dataset.styleId = (this.getAttribute('st') || this.getAttribute('element_type')) ?? '';
    }

    super.preRender();
  }

  attributeChangedCallback(attribute: string, oldValue: string, newValue: string) {
    if (oldValue !== newValue) {
      switch (attribute) {
        case 'data-left-indentation':
        case 'data-right-indentation':
        case 'data-special-indent':
        case 'data-special-indent-value':
          this.handleIndentation();
          break;
        case 'data-background-color':
          this.handleBackgroundColor();
          break;
        case 'data-line-height':
          this.handleLineHeightUpdate();
          break;
        case 'data-space-after':
        case 'data-space-before':
        case 'data-asa':
        case 'data-asb':
          this.handleSpacing();
          break;
        case 'data-font-family':
          this.handleFontFamily();

          this.handleLineHeightUpdate();
          break;
        case 'data-font-size':
          this.handleFontSize();
          this.handleLineHeightUpdate();
          break;
        case 'data-color':
          this.handleColor();
          break;
        case 'data-bold':
          this.handleBold();
          break;
        case 'data-italic':
          this.handleItalic();
          break;
        case 'data-underline':
          this.handleUnderline();
          break;
        case 'data-strikethrough':
          this.handleStrikethrough();
          break;
        case 'data-superscript':
          this.handleSuperscript();
          break;
        case 'data-subscript':
          this.handleSubscript();
          break;
        case 'data-highlight-color':
          this.handleHighLightColor();
          break;
        case 'data-vanish':
          // this._handleVanish();
          break;
        default:
          super.attributeChangedCallback(attribute, oldValue, newValue);
          break;
      }
    }
  }

  disconnectedCallback() {
    if (this.Visualizer?.hooks?.afterParagraphStyleUpdate) {
      this.Visualizer.hooks.afterParagraphStyleUpdate.unregister(this.handleParagraphStyleUpdate);
    }
    super.disconnectedCallback();
  }

  get styleId() {
    return this.dataset.styleId || this.getAttribute('st');
  }

  get ALLOWED_STYLE_ATTRIBUTES() {
    return (
      StylesUtils.ALLOWED_BLOCK_ATTRIBUTES_BY_ELEMENT[this.tag] || super.getAllowedStyleAttributes()
    );
  }

  get displayType() {
    return DISPLAY_TYPES.BLOCK;
  }

  get isEditable() {
    return !this.isLocked;
  }

  get isDeletable() {
    return !this.isLocked;
  }

  get isSplitable() {
    return !this.isLocked;
  }

  get isSelectable() {
    return true;
  }

  get splitFrom() {
    return this.dataset.splitFrom;
  }

  get splitIndex() {
    if (this.dataset.splitIndex) {
      return +this.dataset.splitIndex;
    } else if (this.viewModel) {
      this.viewModel.indexOfSplitView(this);
    }

    return -1;
  }

  // private handleDataAttributes() {
  //   this.handleIndentation();

  //   if (this.dataset.backgroundColor) {
  //     this.handleBackgroundColor();
  //   }

  //   if (
  //     this.dataset.spaceAfter ||
  //     this.dataset.spaceBefore ||
  //     this.dataset.asa ||
  //     this.dataset.asb
  //   ) {
  //     this.handleSpacing();
  //   }

  //   if (this.dataset[StylesUtils.STYLES.FONTFAMILY]) {
  //     this.handleFontFamily();
  //   }

  //   if (this.dataset[StylesUtils.STYLES.FONTSIZE]) {
  //     this.handleFontSize();
  //   }

  //   if (this.dataset[StylesUtils.STYLES.COLOR]) {
  //     this.handleColor();
  //   }

  //   if (this.dataset[StylesUtils.STYLES.BOLD]) {
  //     this.handleBold();
  //   }

  //   if (this.dataset[StylesUtils.STYLES.ITALIC]) {
  //     this.handleItalic();
  //   }

  //   if (this.dataset[StylesUtils.STYLES.UNDERLINE]) {
  //     this.handleUnderline();
  //   }

  //   if (this.dataset[StylesUtils.STYLES.STRIKETHROUGH]) {
  //     this.handleStrikethrough();
  //   }

  //   if (this.dataset[StylesUtils.STYLES.SUPERSCRIPT]) {
  //     this.handleSuperscript();
  //   }

  //   if (this.dataset[StylesUtils.STYLES.SUBSCRIPT]) {
  //     this.handleSubscript();
  //   }

  //   if (this.dataset[StylesUtils.STYLES.HIGHLIGHTCOLOR]) {
  //     this.handleHighLightColor();
  //   }

  //   if (this.dataset[StylesUtils.STYLES.VANISH]) {
  //     // this._handleVanish();
  //   }

  //   if (!this.dataset.styleId) {
  //     this.dataset.styleId = (this.getAttribute('st') || this.getAttribute('element_type')) ?? '';
  //   }

  //   this.handleLineHeight();
  // }

  clearFormatting() {
    this.removeAttribute('style');
    this.removeAttribute('data-background-color');

    this.removeStyleAttributes(Object.keys(this.getStyleAttributes()) as Editor.Styles.Styles[]);
  }

  setLineHeight(lineHeight: string | null) {
    if (lineHeight != null) {
      this.dataset.lineHeight = lineHeight;
    }
  }

  setSpaceAfter(spaceAfter: string | null) {
    if (spaceAfter != null) {
      this.dataset.spaceAfter = spaceAfter;
    }
  }

  setSpaceBefore(spaceBefore: string | null) {
    if (spaceBefore != null) {
      this.dataset.spaceBefore = spaceBefore;
    }
  }

  setAutoSpaceBefore(autoSpaceBefore: string | boolean | null) {
    if (autoSpaceBefore != null) {
      if (autoSpaceBefore === 'true' || autoSpaceBefore === true) {
        this.dataset.asb = 'true';
      } else {
        this.dataset.asb = 'false';
      }
    } else {
      delete this.dataset.asb;
    }
  }

  setAutoSpaceAfter(autoSpaceafter: string | boolean | null) {
    if (autoSpaceafter != null) {
      if (autoSpaceafter === 'true' || autoSpaceafter === true) {
        this.dataset.asa = 'true';
      } else {
        this.dataset.asa = 'false';
      }
    } else {
      delete this.dataset.asa;
    }
  }

  setIndentation(left: string, right: string) {
    if (left != null) {
      this.dataset.leftIndentation = left;
    }

    if (right != null) {
      this.dataset.rightIndentation = right;
    }
  }

  setWidowControl(value: string | boolean | null) {
    if (value != null) {
      if (value === 'true' || value === true) {
        this.dataset.wc = 'true';
      } else {
        this.dataset.wc = 'false';
      }
    } else {
      delete this.dataset.wc;
    }
  }

  setKeepNext(value: string | boolean | null) {
    if (value != null) {
      if (value === 'true' || value === true) {
        this.dataset.kn = 'true';
      } else {
        this.dataset.kn = 'false';
      }
    } else {
      delete this.dataset.kn;
    }
  }

  getKeepWithNext() {
    if (this.dataset.kn != null) {
      return this.dataset.kn === 'true';
    }
  }

  setKeepLines(value: string | boolean | null) {
    if (value != null) {
      if (value === 'true' || value === true) {
        this.dataset.kl = 'true';
      } else {
        this.dataset.kl = 'false';
      }
    } else {
      delete this.dataset.kl;
    }
  }

  getKeepLines() {
    if (this.dataset.kl != null) {
      return this.dataset.kl === 'true';
    }
  }

  setPageBreakBefore(value: string | boolean | null) {
    if (value != null) {
      if (value === 'true' || value === true) {
        this.dataset.pbb = 'true';
      } else {
        this.dataset.pbb = 'false';
      }
    } else {
      delete this.dataset.pbb;
    }
  }

  getPageBreakBefore() {
    if (this.dataset.pbb != null) {
      return this.dataset.pbb === 'true';
    }
  }

  private handleParagraphStyleUpdate(styleId: string) {
    if (this.styleId === styleId) {
      this.scheduleLineHeightUpdate();
    }
  }

  private scheduleLineHeightUpdate() {
    if (this.timeoutLineHeightUpdate) {
      clearTimeout(this.timeoutLineHeightUpdate);
    }
    this.timeoutLineHeightUpdate = setTimeout(() => {
      delete this.timeoutLineHeightUpdate;
      this.handleLineHeightUpdate();
    }, 0);
  }

  private handleLineHeightUpdate() {
    this.handleLineHeight();

    if (this.isConnected) {
      const formatElements = this.querySelectorAll(
        'format-element[fontfamily],format-element[fontsize]',
      );

      for (let i = 0; i < formatElements.length; i++) {
        const format = formatElements[i];
        if (EditorDOMElements.isFormatElement(format)) {
          format.handleLineHeight();
        }
      }
    }
  }

  checkEmptyContent() {
    if (!this.isConnected) {
      return;
    }

    const doubleStateElements = this.querySelectorAll(
      EditorDOMElements.INLINE_DOUBLE_STATE_ELEMENTS.join(','),
    ) as NodeListOf<BaseBlockElement>;

    const brFound = this.querySelector('br');

    let lastElementChild;

    if (this.lastElementChild instanceof BaseViewElement) {
      lastElementChild = this.lastElementChild;
    }

    const paragraphMarker = lastElementChild?.isParagraphMarker();

    let isEmpty = false;

    const queue: Node[] = [this];

    while (queue.length) {
      const element = queue.shift();

      if (element) {
        if (EditorDOMUtils.isEmptyElement(element)) {
          isEmpty = true;
          if (element.childNodes.length) {
            queue.unshift(...Array.from(element.childNodes));
          }
        } else {
          isEmpty = false;
          break;
        }
      }
    }

    if (isEmpty && !paragraphMarker && doubleStateElements.length === 0) {
      if (!brFound) {
        const br = DOMElementFactory.buildElement('BR');
        let elementToAppendBr = this as HTMLElement;

        while (
          elementToAppendBr.lastChild &&
          elementToAppendBr.nodeType !== Node.TEXT_NODE &&
          elementToAppendBr.firstChild === elementToAppendBr.lastChild &&
          EditorDOMElements.INLINE_EDITABLE_ELEMENTS.includes(
            (elementToAppendBr.lastChild as HTMLElement).tagName,
          )
        ) {
          elementToAppendBr = elementToAppendBr.lastChild as HTMLElement;
        }
        elementToAppendBr.appendChild(br);
      }
      this.style.setProperty('--afterContent', '\u200B');
    } else {
      if (brFound) {
        brFound.remove();
      }
      this.style.setProperty('--afterContent', 'none');
    }

    // check double state elements, ex: placeholder-element, etc
    for (let i = 0; i < doubleStateElements.length; i++) {
      doubleStateElements[i].validateOnlyChildState?.();
    }
  }

  private handleBackgroundColor() {
    const bg = this.dataset.backgroundColor;
    if (bg != null && bg !== 'null') {
      if (bg === 'rgba(0, 0, 0, 0)' || bg === 'transparent' || bg === 'false') {
        this.style.backgroundColor = 'rgba(0,0,0,0)';
      } else if (bg.includes('rgb') || bg.includes('#')) {
        this.style.backgroundColor = bg;
      } else {
        this.style.backgroundColor = `#${bg}`;
      }
    } else {
      this.style.backgroundColor = '';
    }
  }

  private handleLineHeight() {
    if (this.Visualizer && this.Data) {
      const styleId = this.styleId;

      let lineHeight: string | number | undefined = this.getStyleAttribute('lineHeight');
      let fontFamily = this.getStyleAttribute('fontFamily');
      let fontSize: string | number | undefined = this.getStyleAttribute('fontSize');

      if ((lineHeight != null || fontFamily != null || fontSize != null) && styleId) {
        const style = this.Data.styles.documentStyles.style(styleId);

        if (lineHeight == null && style?.extendedP?.lh) {
          lineHeight = style.extendedP?.lh;
        }

        if (fontFamily == null && style?.extendedP?.fontfamily) {
          fontFamily = style?.extendedP?.fontfamily;
        }

        if (fontSize == null && style?.extendedP?.fontsize) {
          fontSize = style?.extendedP?.fontsize;
        }

        if (lineHeight && fontFamily && fontSize) {
          const metrics = this.Visualizer.fontFamilyHelper?.getTextMetrics(
            fontFamily,
            `${fontSize}pt`,
          );
          let calculateLineHeight = +lineHeight;
          if (metrics?.fontBoundingBoxAscent != null && metrics?.fontBoundingBoxDescent != null) {
            calculateLineHeight =
              (metrics.fontBoundingBoxAscent + metrics.fontBoundingBoxDescent) * +lineHeight;
            this.style.setProperty('--lineHeight', `${calculateLineHeight}px`);
          } else {
            this.style.setProperty('--lineHeight', `${calculateLineHeight}`);
          }
        } else {
          this.style.removeProperty('--lineHeight');
        }
      } else {
        this.style.removeProperty('--lineHeight');
      }
    }
  }

  private handleSpacing() {
    if (this.dataset.asb === 'true') {
      this.style.marginTop = '14pt';
    } else if (this.dataset.spaceBefore != null && this.dataset.spaceBefore !== '') {
      this.style.marginTop = this.dataset.spaceBefore ? `${this.dataset.spaceBefore}pt` : '';
    } else if (this.dataset.asb === 'false') {
      this.style.marginTop = '0pt';
    } else {
      this.style.marginTop = '';
    }

    if (this.dataset.asa === 'true') {
      this.style.marginBottom = '14pt';
    } else if (this.dataset.spaceAfter != null && this.dataset.spaceAfter !== '') {
      this.style.marginBottom = this.dataset.spaceAfter ? `${this.dataset.spaceAfter}pt` : '';
    } else if (this.dataset.asa === 'false') {
      this.style.marginBottom = '0pt';
    } else {
      this.style.marginBottom = '';
    }
  }

  private handleIndentation() {
    if (this.dataset.leftIndentation != null) {
      let value = 0;

      const leftIndentation = +this.dataset.leftIndentation;

      if (leftIndentation > 0) {
        value = Math.min(StylesUtils.INDENTATION_LIMITS.LEFT.RENDER_MAX, leftIndentation);
      } else {
        value = Math.max(StylesUtils.INDENTATION_LIMITS.LEFT.RENDER_MIN, leftIndentation);
      }

      this.style.setProperty('--leftMargin', `${value}pt`);
    } else {
      this.style.removeProperty('--leftMargin');
    }

    if (this.dataset.rightIndentation != null) {
      let value = 0;
      if (+this.dataset.rightIndentation > 0) {
        value = Math.min(
          StylesUtils.INDENTATION_LIMITS.RIGHT.RENDER_MAX,
          +this.dataset.rightIndentation,
        );
      } else {
        value = Math.max(
          StylesUtils.INDENTATION_LIMITS.RIGHT.RENDER_MIN,
          +this.dataset.rightIndentation,
        );
      }

      this.style.setProperty('--rightMargin', `${value}pt`);
    } else {
      this.style.removeProperty('--rightMargin');
    }

    if (this.dataset.specialIndentValue != null) {
      let specialIndentValue = +this.dataset.specialIndentValue;

      if (specialIndentValue > 0) {
        specialIndentValue = Math.min(
          StylesUtils.INDENTATION_LIMITS.SPECIAL_INDENT.RENDER_MAX,
          specialIndentValue,
        );
      } else {
        specialIndentValue = Math.max(
          StylesUtils.INDENTATION_LIMITS.SPECIAL_INDENT.RENDER_MIN,
          specialIndentValue,
        );
      }

      switch (this.dataset.specialIndent) {
        case INDENT_TYPE.HANGING:
          this.style.textIndent = `${-specialIndentValue}pt`;
          this.style.setProperty('--leftPadding', `${specialIndentValue}pt`);
          this.style.setProperty('--hanging', `${specialIndentValue}pt`);
          this.style.setProperty('--beforeDisplay', 'inline-block');
          this.style.setProperty('--beforeWidth', `${Math.abs(specialIndentValue)}pt`);
          this.style.setProperty('--beforeTextIndent', '0pt');
          this.style.setProperty('--afterContent', 'none');
          break;

        case INDENT_TYPE.FIRST_LINE:
          this.style.textIndent = `${specialIndentValue}pt`;
          this.style.setProperty('--leftPadding', '0pt');
          this.style.setProperty('--hanging', '0pt');
          this.style.removeProperty('--beforeDisplay');
          this.style.removeProperty('--beforeWidth');
          this.style.removeProperty('--beforeTextIndent');
          this.style.setProperty('--afterContent', 'none');
          break;

        default:
          this.removeAttribute('data-special-indent-value');
          this.removeAttribute('data-special-indent');
          this.style.removeProperty('text-indent');
          this.style.removeProperty('--leftPadding');
          this.style.removeProperty('--hanging');
          this.style.removeProperty('--beforeDisplay');
          this.style.removeProperty('--afterContent');
          this.style.removeProperty('--beforeWidth');
          this.style.removeProperty('--beforeTextIndent');
          break;
      }
    }
  }

  private handleHighLightColor() {
    if (this.hasStyleAttribute(StylesUtils.STYLES.HIGHLIGHTCOLOR)) {
      const hc = this.dataset[StylesUtils.STYLES.HIGHLIGHTCOLOR];
      if (hc != null && hc !== 'null') {
        if (hc === 'false') {
          this.style.setProperty('--highlightColor', 'rgba(0,0,0,0)');
        } else if (hc.includes('rgb') || hc.includes('#')) {
          this.style.setProperty('--highlightColor', hc);
        } else {
          this.style.setProperty('--highlightColor', `#${hc}`);
        }
      } else {
        this.style.removeProperty('--highlightColor');
      }
    }
  }

  private handleFontFamily() {
    if (this.hasStyleAttribute(StylesUtils.STYLES.FONTFAMILY)) {
      const fontFamily = this.dataset[StylesUtils.STYLES.FONTFAMILY];
      this.style.setProperty('--fontFamily', `"${fontFamily}"`);

      // validate font family
      const editorManager = EditorManager.getInstance();
      if (editorManager && fontFamily) {
        editorManager.validateFontFamily(fontFamily);
      }
    } else {
      this.style.removeProperty('--fontFamily');
    }
  }

  private handleFontSize() {
    if (this.hasStyleAttribute(StylesUtils.STYLES.FONTSIZE)) {
      const fontSize = this.dataset[StylesUtils.STYLES.FONTSIZE];
      this.style.setProperty('--fontSize', `${fontSize}pt`);
    } else {
      this.style.removeProperty('--fontSize');
    }
  }

  private handleColor() {
    if (this.hasStyleAttribute(StylesUtils.STYLES.COLOR)) {
      const color = this.dataset[StylesUtils.STYLES.COLOR];
      if (color != null) {
        if (color === 'false') {
          this.style.setProperty('--color', 'rgba(0,0,0,0)');
        } else if (color.includes('rgb') || color.includes('#')) {
          this.style.setProperty('--color', color);
        } else {
          this.style.setProperty('--color', `#${color}`);
        }
      }
    } else {
      this.style.removeProperty('--color');
    }
  }

  private handleBold() {
    if (this.hasStyleAttribute(StylesUtils.STYLES.BOLD)) {
      const bold = this.dataset[StylesUtils.STYLES.BOLD];
      if (bold === 'true') {
        this.style.setProperty('--fontWeight', 'bold');
      } else if (bold === 'false') {
        this.style.setProperty('--fontWeight', 'normal');
      }
    } else {
      this.style.removeProperty('--fontWeight');
    }
  }

  private handleItalic() {
    if (this.hasStyleAttribute(StylesUtils.STYLES.ITALIC)) {
      const italic = this.dataset[StylesUtils.STYLES.ITALIC];
      if (italic === 'true') {
        this.style.setProperty('--fontStyle', 'italic');
      } else if (italic === 'false') {
        this.style.setProperty('--fontStyle', 'normal');
      }
    } else {
      this.style.removeProperty('--fontStyle');
    }
  }

  private handleUnderline() {
    if (this.hasStyleAttribute(StylesUtils.STYLES.UNDERLINE)) {
      const underline = this.dataset[StylesUtils.STYLES.UNDERLINE];
      if (underline === 'true') {
        this.style.setProperty('--underline', 'underline');
      } else if (underline === 'false') {
        this.style.removeProperty('--underline');
      }
    } else {
      this.style.removeProperty('--underline');
    }
  }

  private handleStrikethrough() {
    if (this.hasStyleAttribute(StylesUtils.STYLES.STRIKETHROUGH)) {
      const strike = this.dataset[StylesUtils.STYLES.STRIKETHROUGH];
      if (strike === 'true') {
        this.style.setProperty('--strikethrough', 'line-through');
      } else if (strike === 'false') {
        this.style.removeProperty('--strikethrough');
      }
    } else {
      this.style.removeProperty('--strikethrough');
    }
  }

  private handleSuperscript() {
    if (this.hasStyleAttribute(StylesUtils.STYLES.SUPERSCRIPT)) {
      const superscript = this.dataset[StylesUtils.STYLES.SUPERSCRIPT];
      if (superscript === 'true') {
        this.style.setProperty('--verticalAlign', 'super');
        this.style.setProperty('--fontSize', '63%');
      } else if (superscript === 'false') {
        this.style.setProperty('--verticalAlign', 'auto');
        this.style.removeProperty('--fontSize');
      }
    } else {
      this.style.removeProperty('--verticalAlign');
      this.style.removeProperty('--fontSize');
    }
  }

  private handleSubscript() {
    if (this.hasStyleAttribute(StylesUtils.STYLES.SUBSCRIPT)) {
      const subscript = this.dataset[StylesUtils.STYLES.SUBSCRIPT];
      if (subscript === 'true') {
        this.style.setProperty('--verticalAlign', 'sub');
        this.style.setProperty('--fontSize', '63%');
      } else if (subscript === 'false') {
        this.style.setProperty('--verticalAlign', 'auto');
        this.style.removeProperty('--fontSize');
      }
    } else {
      this.style.removeProperty('--verticalAlign');
      this.style.removeProperty('--fontSize');
    }
  }

  // _handleVanish() {
  // if (this.hasStyleAttribute(StylesUtils.STYLES.VANISH)) {
  //   const vanish = this.dataset[StylesUtils.STYLES.VANISH];
  //   if (vanish === true || vanish === 'true') {
  //     this.style.setProperty('--vanish', 'true');
  //   } else if (vanish === false || vanish === 'false') {
  //     this.style.removeProperty('--vanish');
  //   }
  // } else {
  //   this.style.removeProperty('--vanish');
  // }
  // }

  set backgroundColor(color: string | null) {
    this.dataset.backgroundColor = color || undefined;
  }

  static findStep(current: number, next: boolean) {
    const INDENTATION_MIN = StylesUtils.INDENTATION_LIMITS.LEFT.RENDER_MIN;
    const INDENTATION_MAX = StylesUtils.INDENTATION_LIMITS.LEFT.RENDER_MAX;
    const INDENTATION_VALUE = StylesUtils.INDENTATION_LIMITS.INDENTATION_STEP;

    const value = next ? current + INDENTATION_VALUE : current;
    const rest = value % INDENTATION_VALUE;

    let result = current;

    if (Number(parseFloat(rest.toString()).toFixed(2)) === 0) {
      result = next ? current + INDENTATION_VALUE : current - INDENTATION_VALUE;
    } else {
      result = next ? value : value - rest;
    }

    if (result > 0) {
      return Math.min(INDENTATION_MAX, result);
    }
    return Math.max(INDENTATION_MIN, result);
  }

  private changeIndentation(indent: boolean) {
    const manager = EditorManager.getInstance();

    let current = 0;
    if (this.dataset.leftIndentation) {
      current = Number(this.dataset.leftIndentation);
    } else if (this.dataset.styleId && manager.hasStyleIndentation(this.dataset.styleId)) {
      const style = manager.hasStyleIndentation(this.dataset.styleId);
      current = style?.l ?? 0;
    }
    const result = ParagraphElement.findStep(current, indent);
    this.dataset.leftIndentation = result.toString();
  }

  indent() {
    this.changeIndentation(true);
  }

  outdent() {
    this.changeIndentation(false);
  }

  setIndentationProperties(indentationProperties: Editor.Elements.IndentationProperties) {
    Object.keys(indentationProperties).forEach((prop) => {
      let value;
      switch (prop) {
        case 'leftIndentation':
          value = indentationProperties.leftIndentation;
          if (value != null) {
            if (+value > 0) {
              this.dataset.leftIndentation = Math.min(
                StylesUtils.INDENTATION_LIMITS.LEFT.MANIPULATION_MAX,
                +value,
              ).toString();
            } else {
              this.dataset.leftIndentation = Math.max(
                StylesUtils.INDENTATION_LIMITS.LEFT.MANIPULATION_MIN,
                +value,
              ).toString();
            }
          }
          break;
        case 'rightIndentation':
          value = indentationProperties.rightIndentation;
          if (value != null) {
            if (+value > 0) {
              this.dataset.rightIndentation = Math.min(
                StylesUtils.INDENTATION_LIMITS.RIGHT.MANIPULATION_MAX,
                +value,
              ).toString();
            } else {
              this.dataset.rightIndentation = Math.max(
                StylesUtils.INDENTATION_LIMITS.RIGHT.MANIPULATION_MIN,
                +value,
              ).toString();
            }
          }
          break;

        case 'specialIndent':
          if (
            indentationProperties.specialIndent != null &&
            indentationProperties.specialIndentValue != null
          ) {
            let specialIndentValue;
            if (+indentationProperties.specialIndentValue > 0) {
              specialIndentValue = Math.min(
                StylesUtils.INDENTATION_LIMITS.SPECIAL_INDENT.MANIPULATION_MAX,
                +indentationProperties.specialIndentValue,
              );
            } else {
              specialIndentValue = Math.max(
                StylesUtils.INDENTATION_LIMITS.SPECIAL_INDENT.MANIPULATION_MIN,
                +indentationProperties.specialIndentValue,
              );
            }

            if (indentationProperties.specialIndent === INDENT_TYPE.HANGING) {
              this.dataset.specialIndent = INDENT_TYPE.HANGING;
              this.dataset.specialIndentValue = specialIndentValue.toString();
              if (this.dataset.leftIndentation) {
                this.dataset.leftIndentation = (
                  +this.dataset.leftIndentation + specialIndentValue
                ).toString();
              }
            } else if (indentationProperties.specialIndent === INDENT_TYPE.FIRST_LINE) {
              this.dataset.specialIndent = INDENT_TYPE.FIRST_LINE;
              this.dataset.specialIndentValue = specialIndentValue.toString();
            }
          } else {
            delete this.dataset.specialIndent;
            delete this.dataset.specialIndentValue;
          }
          break;
        default:
          break;
      }
    });
  }

  set listId(listId: string) {
    this.setAttribute('list_id', listId);
  }

  get listId() {
    return this.getAttribute('list_id') ?? '';
  }

  set listLevel(listLevel: string) {
    this.setAttribute('list_level', listLevel);
  }

  get listLevel() {
    return this.getAttribute('list_level') ?? '';
  }

  isListElement() {
    return this.hasAttribute('list_id') && this.hasAttribute('list_level');
  }
}

if (!window.customElements.get(ELEMENTS.ParagraphElement.IDENTIFIER)) {
  window.customElements.define(ELEMENTS.ParagraphElement.IDENTIFIER, ParagraphElement);
}
