import { ElementNodeBuilder } from 'Editor/services/Model';
import { ELEMENTS } from 'Editor/services/consts';
import DOMElementFactory from 'Editor/services/DOMUtilities/DOMElementFactory/DOMElementFactory';
import { BaseViewBuilder } from '../BaseViewBuilder';
import { EditorDOMUtils } from 'Editor/services/_Common/DOM';
import { TableElement } from './TableElement/TableElement';

export class TableViewBuilder
  extends BaseViewBuilder
  implements Editor.Visualizer.IViewbuilder<TableElement, Editor.Data.Node.TableData>
{
  ATTRIBUTE_MAPPER: Editor.Visualizer.ATTRIBUTE_MAPPER_TYPE<
    TableElement,
    Editor.Data.Node.TableData
  > = {
    // id
    id: this.GENERIC_ATTRIBUTE_MAPPER.id,
    // parent id
    parent_id: this.GENERIC_ATTRIBUTE_MAPPER.parent_id,
    // element_type
    element_type: {
      parse: this.GENERIC_ATTRIBUTE_MAPPER.element_type.parse,
      render: (json, node: HTMLElement) => {
        node.setAttribute('element_type', ELEMENTS.TableElement.ELEMENT_TYPE);
      },
      remove: this.GENERIC_ATTRIBUTE_MAPPER.element_type.remove,
    },
    // alignment
    a: this.GENERIC_ATTRIBUTE_MAPPER.a,
    // indentation
    ind: this.GENERIC_ATTRIBUTE_MAPPER.ind,
    // cell widths
    cw: {
      parse: (node: TableElement, builder: ElementNodeBuilder) => {
        // if (node.tagName === ELEMENTS.TableElement.TAG) {
        //   let cw: any[] = [];
        //   const tHead = node.tHead as HTMLTableSectionElement;
        //   const tbody = node.tBodies[0] as HTMLTableSectionElement;
        //   if (node.dataset.cw != null) {
        //     cw = node.dataset.cw.split(',').map((value) => {
        //       return DOMUtils.convertUnitTo(value, 'pt', 'pt', 3);
        //     });
        //   } else if (tHead) {
        //     Array.from(tHead.rows[0].cells).forEach((cell) => {
        //       const width = DOMUtils.convertUnitTo(cell.style.width, undefined, 'pt', 3);
        //       cw.push(width);
        //     });
        //   }
        //   if (cw.length === 0) {
        //     Array.from(tbody.rows[0].cells).forEach((cell) => {
        //       const width = DOMUtils.convertUnitTo(cell.style.width, undefined, 'pt', 3);
        //       cw.push(width);
        //     });
        //   }
        //   if (cw.length > 0) {
        //     builder.addProperty('cw', cw);
        //   }
        // }
      },
      render: (json, node: TableElement) => {
        if (json.properties?.cw != null) {
          node.dataset.cw = json.properties.cw.join(',');
        }
      },
      remove: (node: TableElement) => {
        delete node.dataset.cw;
        if (node.tHead) {
          node.tHead.remove();
        }
      },
    },
    // cell border
    cb: {
      parse: (node: TableElement, builder: ElementNodeBuilder) => {
        if (node.tagName === ELEMENTS.TableElement.TAG) {
          const borders = node.cellBorders;
          if (borders != null && Object.keys(borders).length > 0) {
            builder.addProperty('cb', borders);
          }
        }
      },
      render: (
        json,
        node: TableElement,
        baseNodeData?,
        attributeName?: string,
        options: any = { buildStyle: true },
      ) => {
        if (node.tagName === ELEMENTS.TableElement.TAG) {
          if (json.properties?.cb != null) {
            node.cellBorders = json.properties.cb;
            if (options?.buildStyle) {
              node.buildStyleElement();
            }
          }
        }
      },
      remove: (node: TableElement, attributeName?: string, options: any = { buildStyle: true }) => {
        //@ts-expect-error
        delete node.cellBorders;
        if (options?.buildStyle) {
          node.buildStyleElement();
        }
      },
    },
    // cell padding
    cp: {
      parse: (node: TableElement, builder: ElementNodeBuilder) => {
        if (node.tagName === ELEMENTS.TableElement.TAG) {
          const paddings = node.cellPaddings;
          if (paddings != null && Object.keys(paddings).length > 0) {
            builder.addProperty('cp', paddings);
          }
        }
      },
      render: (json, node: TableElement) => {
        if (node.tagName === ELEMENTS.TableElement.TAG) {
          if (json.properties?.cp != null) {
            node.cellPaddings = json.properties.cp;
          }
        }
      },
      remove: (node: TableElement) => {
        //@ts-expect-error
        delete node.cellPaddings;
      },
    },
    // cell background
    bg: {
      parse: (node: TableElement, builder: ElementNodeBuilder) => {
        if (node.dataset.backgroundColor != null) {
          const bg: any = node.dataset.backgroundColor;
          if (bg != null) {
            if (
              bg === 'rgba(0, 0, 0, 0)' ||
              bg === 'transparent' ||
              bg === false ||
              bg === 'false'
            ) {
              builder.addProperty('bg', false);
            } else if (bg.includes('rgb')) {
              const hex: any = EditorDOMUtils.rgbToHex(bg);
              builder.addProperty('bg', hex.substring(1, bg.length).toUpperCase?.());
            } else if (bg.includes('#')) {
              builder.addProperty('bg', bg.substring(1, bg.length).toUpperCase?.());
            } else {
              builder.addProperty('bg', bg.toUpperCase?.());
            }
          }
        }
      },
      render: (json, node: TableElement) => {
        if (json.properties?.bg != null) {
          const bg = json.properties.bg;
          if (bg != null) {
            node.dataset.backgroundColor = bg;
          }
        }
      },
      remove: (node: TableElement) => {
        delete node.dataset.backgroundColor;
      },
    },
    // cell vertical align
    cva: {
      parse: (node: TableElement, builder: ElementNodeBuilder) => {
        if (node.tagName === ELEMENTS.TableElement.TAG) {
          const tbody = node.tBodies[0] as HTMLTableSectionElement;
          if (tbody && tbody.dataset.verticalAlignment != null) {
            builder.addProperty(
              'cva',
              BaseViewBuilder.verticalAlignmentMapper.parse(tbody.dataset.verticalAlignment),
            );
          }
        }
      },
      render: (json, node: TableElement) => {
        if (json.properties?.cva != null) {
          const tbody = node.tBodies[0] as HTMLTableSectionElement;
          if (tbody) {
            tbody.dataset.verticalAlignment = BaseViewBuilder.verticalAlignmentMapper.render(
              json.properties.cva,
            );
          }
        }
      },
      remove: (node: TableElement) => {
        delete (node.tBodies[0] as HTMLTableSectionElement)?.dataset.verticalAlignment;
      },
    },
    // tasks
    tasks: this.GENERIC_ATTRIBUTE_MAPPER.tasks,
    // status
    status: this.GENERIC_ATTRIBUTE_MAPPER.status,
    // cell text alignment
    ca: {
      parse: (node: TableElement, builder: ElementNodeBuilder) => {
        if (node.dataset.textAlignment != null) {
          builder.addProperty(
            'ca',
            BaseViewBuilder.alignmentMapper.parse(node.dataset.textAlignment),
          );
        }
      },
      render: (json, node: TableElement) => {
        if (json.properties?.ca != null) {
          node.dataset.textAlignment = BaseViewBuilder.alignmentMapper.render(json.properties.ca);
        }
      },
      remove: (node: TableElement) => {
        delete node.dataset.textAlignment;
      },
    },
    //section
    section: this.GENERIC_ATTRIBUTE_MAPPER.section,
    // table width
    w: {
      parse: (node, builder, attributeName) => {
        const parsedWidth: Partial<Editor.Data.Node.TableWidth> = {};
        if (node.dataset.width != null) {
          const width = node.dataset.width.split(',');
          parsedWidth.t = width[0] as Editor.Data.Node.TableWidthTypes;
          parsedWidth.v = +width[1];
        }
        builder.addProperty('w', parsedWidth);
      },
      render: (json, node) => {
        if (json.properties?.w != null) {
          node.dataset.width = [json.properties.w.t, json.properties.w.v].join(',');
        } else if (json.properties?.cw != null) {
          const width = json.properties.cw.reduce((value, result) => result + value, 0);
          node.dataset.width = ['abs', width].join(',');
        }
      },
      remove: (node) => {
        delete node.dataset.width;
      },
    },
    // table text wrapping
    wr: {
      parse: (node: TableElement, builder: ElementNodeBuilder) => {
        if (node.dataset.wr != null) {
          const parsedWrapping: Editor.Data.Node.TableWrapping = JSON.parse(node.dataset.wr);
          builder.addProperty('wr', parsedWrapping);
        }
      },
      render: (json, node) => {
        if (json.properties?.wr != null) {
          node.dataset.wr = JSON.stringify(json.properties.wr);
        }
      },
      remove: (node) => {
        delete node.dataset.wr;
      },
    },
    // auto resize
    ar: {
      parse: (node: TableElement, builder: ElementNodeBuilder) => {
        if (node.dataset.ar != null) {
          builder.addProperty('ar', node.dataset.ar === 'true');
        }
      },
      render: (json, node) => {
        if (json.properties?.ar != null) {
          node.dataset.ar = String(json.properties.ar);
        }
      },
      remove: (node) => {
        delete node.dataset.ar;
      },
    },

    // clipboard temp attribute
    cp_cell_borders: {
      parse: (node: TableElement, builder: ElementNodeBuilder) => {
        const bordersAtt = node.getAttribute('cp_cell_borders');
        if (bordersAtt) {
          const borders = JSON.parse(bordersAtt);
          if (borders != null && Object.keys(borders).length > 0) {
            builder.addProperty('cb', borders);
          }
        }
      },
      render: (json, node: TableElement) => {},
      remove: (node: TableElement) => {
        node.removeAttribute('cp_cell_borders');
      },
    },
    // clipboard temp attribute
    cp_cell_paddings: {
      parse: (node: TableElement, builder: ElementNodeBuilder) => {
        const paddingsAtt = node.getAttribute('cp_cell_paddings');
        if (paddingsAtt) {
          const paddings = JSON.parse(paddingsAtt);
          if (paddings != null && Object.keys(paddings).length > 0) {
            builder.addProperty('cp', paddings);
          }
        }
      },
      render: (json, node: TableElement) => {},
      remove: (node: TableElement) => {
        node.removeAttribute('cp_cell_paddings');
      },
    },
  };

  get attributeMapper() {
    return this.ATTRIBUTE_MAPPER;
  }

  shouldRenderChildren(json?: Editor.Data.Node.TableData | undefined) {
    return true;
  }

  build(json: Editor.Data.Node.TableData, model?: Editor.Data.Node.Model): TableElement {
    const node = DOMElementFactory.buildElement({
      tagName: ELEMENTS.TableElement.TAG,
      is: ELEMENTS.TableElement.IDENTIFIER,
    }) as TableElement;

    node.setAttribute('contenteditable', 'false');

    const baseData = model?.get();

    Object.keys(this.ATTRIBUTE_MAPPER).forEach((prop) => {
      this.ATTRIBUTE_MAPPER[prop].render(json, node, baseData, undefined, undefined);
    });

    node.Visualizer = this.Visualizer;

    const baseElement = baseData.id === json.id;

    let pageWidth;
    if (baseElement && json.id) {
      pageWidth = this.Data?.sections.getPageWidthForBlockId(json.id);
    }

    node.preRender(baseElement, pageWidth);

    return node;
  }
}
