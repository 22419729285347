import tippy, { Instance } from 'tippy.js';
import Intl from 'Intl/Intl';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { store } from '_common/redux';
import UserCard from '_common/components/UserCard/UserCard';
import DOMElementFactory from 'Editor/services/DOMUtilities/DOMElementFactory/DOMElementFactory';
import { DISPLAY_TYPES } from 'Editor/services/consts';
import { EditorDOMElements } from 'Editor/services/_Common/DOM';
import { BaseViewElement } from './BaseViewElement';

export class BaseBlockElement extends BaseViewElement {
  protected isLocked: boolean;
  protected tooltipElement?: HTMLElement;
  protected tippyInstance?: Instance | null = null;
  protected lockTimeout?: NodeJS.Timeout | null = null;

  constructor(Visualizer?: Editor.Visualizer.State, Data?: Editor.Data.API) {
    super(Visualizer, Data);
    this.isLocked = false;
  }

  static get observedAttributes() {
    return ['lock'];
  }

  attributeChangedCallback(attribute: string, oldValue: string, newValue: string) {
    if (oldValue !== newValue) {
      switch (attribute) {
        case 'lock':
          this.handleLockStatus();
          break;
        default:
          break;
      }
    }
  }

  connectedCallback() {
    super.connectedCallback();
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    if (this.tippyInstance) {
      this.tippyInstance.destroy();
      this.tippyInstance = null;
    }
  }

  private handleLockStatus() {
    const lock = this.getAttribute('lock');

    if (lock != null && lock !== 'false') {
      this.isLocked = true;

      if (typeof lock === 'string' && lock !== 'true') {
        // locked from server operation

        const [user] = lock.split('|') ?? [''];
        // set tooltip instance
        if (this.tippyInstance) {
          this.tippyInstance.destroy();
          delete this.tippyInstance;
        }

        this.tooltipElement = DOMElementFactory.buildElement('div');
        this.tippyInstance = tippy(this, { content: this.tooltipElement, duration: 1 }) as Instance;
        ReactDOM.unmountComponentAtNode(this.tooltipElement);
        ReactDOM.render(
          <Provider store={store}>
            <Intl>
              <UserCard userId={`${user}`} />
            </Intl>
          </Provider>,
          this.tooltipElement,
        );

        // FAILSAFE: set timeout to unlock (only when lock comes from server)
        if (this.lockTimeout) {
          clearTimeout(this.lockTimeout);
          delete this.lockTimeout;
        }
        this.lockTimeout = setTimeout(() => {
          this.removeAttribute('lock');
          delete this.lockTimeout;
        }, 6000);
      } else if (lock === 'true') {
        // locked from client - readonly
        this.tippyInstance?.destroy();
        delete this.tippyInstance;
      }
    } else {
      this.isLocked = false;

      if (this.tippyInstance) {
        this.tippyInstance.destroy();
        delete this.tippyInstance;
      }
      if (this.lockTimeout) {
        clearTimeout(this.lockTimeout);
        delete this.lockTimeout;
      }
    }
  }

  hasTasks() {
    return this.hasAttribute('task');
  }

  getTasks() {
    return this.getAttribute('task')?.split(',') ?? [];
  }

  //TODO
  validateOnlyChildState() {}

  preRender() {
    super.preRender();
  }

  set selectedTask(value: boolean) {
    if (value) {
      this.setAttribute('selectedTask', 'true');
    } else {
      this.removeAttribute('selectedTask');
    }
  }

  selectTask() {
    this.selectedTask = true;
  }

  deselectTask() {
    this.selectedTask = false;
  }

  get displayType(): Editor.Elements.DisplayType | null {
    // return INLINE / BLOCK
    return DISPLAY_TYPES.BLOCK;
  }

  get enclosedElementId() {
    return this.getAttribute('enclosed_element');
  }

  get selectableContent(): Node | null {
    return this;
  }

  get isEditable() {
    return EditorDOMElements.EDITABLE_LEVEL0_ELEMENTS.includes(this.tagName);
  }

  get isDeletable() {
    return EditorDOMElements.DELETABLE_LEVEL0_ELEMENTS.includes(this.tagName);
  }

  get isSplitable() {
    return EditorDOMElements.DEFAULT_SPLITABLE_LEVEL0_ELEMENTS.includes(this.tagName);
  }

  get isContentWrapper() {
    return EditorDOMElements.WRAPPER_LEVEL0_ELEMENTS.includes(this.tagName);
  }

  // --------------------------------------------------
  //                Style Attributes
  // --------------------------------------------------
  get ALLOWED_STYLE_ATTRIBUTES(): Editor.Styles.Styles[] {
    return [];
  }

  getAllowedStyleAttributes() {
    return this.ALLOWED_STYLE_ATTRIBUTES;
  }

  hasStyleAttribute(attribute: Editor.Styles.Styles) {
    if (attribute != null && this.ALLOWED_STYLE_ATTRIBUTES.includes(attribute)) {
      return (
        this.dataset[attribute] != null &&
        this.dataset[attribute] !== 'null' &&
        this.dataset[attribute] !== ''
      );
    }
    return false;
  }

  getStyleAttribute(attribute: Editor.Styles.Styles) {
    if (attribute != null && this.hasStyleAttribute(attribute)) {
      return this.dataset[attribute];
    }
    return undefined;
  }

  getStyleAttributes() {
    const styleAttributes: Editor.Styles.StylesApplied = {};

    let i;
    for (i = 0; i < this.ALLOWED_STYLE_ATTRIBUTES.length; i++) {
      const styleKey = this.ALLOWED_STYLE_ATTRIBUTES[i];
      const attribute = this.getStyleAttribute(styleKey);
      if (attribute != null && styleKey != null) {
        styleAttributes[styleKey] = attribute;
      }
    }

    return styleAttributes;
  }

  removeStyleAttribute(attribute: Editor.Styles.Styles, force: boolean = false) {
    if (attribute != null && (force || this.hasStyleAttribute(attribute))) {
      delete this.dataset[attribute];
    }
  }

  removeStyleAttributes(attributes: Editor.Styles.Styles[] = []) {
    if (Array.isArray(attributes)) {
      let i;
      for (i = 0; i < attributes.length; i++) {
        this.removeStyleAttribute(attributes[i]);
      }
    }
  }

  addStyleAttribute(attribute: Editor.Styles.Styles, value: string | boolean | null) {
    if (attribute != null && this.ALLOWED_STYLE_ATTRIBUTES.includes(attribute)) {
      if (value != null) {
        this.dataset[attribute] = `${value}`;
      } else {
        this.removeStyleAttribute(attribute, true);
      }
    }
  }
}
