import './FormatElement.module.scss';
import EditorManager from 'Editor/services/EditorManager';
import { ELEMENTS } from 'Editor/services/consts';
import { EditorDOMElements, EditorDOMUtils } from 'Editor/services/_Common/DOM';
import { BaseViewElement } from '../../BaseViewBuilder';

export const ATTRIBUTES = [
  'bold',
  'italic',
  'underline',
  'strikethrough',
  'superscript',
  'subscript',
  'fontfamily',
  'fontsize',
  'style',
  'color',
  'highlightcolor',
  'vanish',
] as const;
const ATTRIBUTES_LENGTH = ATTRIBUTES.length;

type Styles = {
  fontFamily: string;
  fontSize: string;
  color: string;
  highlightColor: string;
  bold: boolean;
  italic: boolean;
  underline: boolean;
  strikethrough: boolean;
  superscript: boolean;
  subscript: boolean;
  vanish: boolean;
};

const parseBool = (value: boolean | string) => {
  return value === true || value === 'true';
};

const parseString = (value: boolean) => {
  return value;
};

const ATTRIBUTE_PARSE = {
  bold: parseBool,
  italic: parseBool,
  underline: parseBool,
  strikethrough: parseBool,
  superscript: parseBool,
  subscript: parseBool,
  fontfamily: parseString,
  fontsize: parseString,
  style: parseString,
  color: parseString,
  highlightcolor: parseString,
  vanish: parseBool,
} as const;

function compareAttribute(key: keyof typeof ATTRIBUTE_PARSE, attrA: any, attrB: any) {
  if (ATTRIBUTE_PARSE[key](attrA) === ATTRIBUTE_PARSE[key](attrB)) {
    return 0;
  }
  return -1;
}

export class FormatElement extends BaseViewElement {
  static get observedAttributes() {
    return [
      'fontsize',
      'color',
      'highlightcolor',
      'fontfamily',
      'underline',
      'strikethrough',
      'vanish',
    ];
  }

  connectedCallback() {
    super.connectedCallback();

    if (!this.preRendered) {
      // this.preRender();
    }

    // TODO: this should not be on this callback
    this.handleLineHeight();
  }

  preRender() {
    // this._handleFontSize();

    // this._handleColor();

    // this._handleHighlighColor();

    // this._handleFontFamily();

    // this._handleTextDecoration();

    super.preRender();
  }

  attributeChangedCallback(name: string, oldValue: string, newValue: string) {
    if (oldValue !== newValue) {
      if (name === 'fontsize') {
        this._handleFontSize();
        this.handleLineHeight();
      } else if (name === 'color') {
        this._handleColor();
      } else if (name === 'highlightcolor') {
        this._handleHighlighColor();
      } else if (name === 'fontfamily') {
        this._handleFontFamily();
        this.handleLineHeight();
      } else if (name === 'strikethrough' || name === 'underline' || name === 'vanish') {
        this._handleTextDecoration();
      }
    }
  }

  _handleTextDecoration() {
    const vanish = this.getAttribute('vanish');
    const underline = this.getAttribute('underline');
    const strikethrough = this.getAttribute('strikethrough');

    let line = '';
    let color = '';
    let style = '';

    if (vanish === 'true') {
      line = 'underline';
      color = 'var(--colors-blue-100)';
      style = 'dashed';

      if (strikethrough === 'true') {
        line += ' line-through';
      }
    } else {
      if (underline === 'true') {
        line = 'underline';
      }

      if (strikethrough === 'true') {
        if (line == null) {
          line = 'line-through';
        } else {
          line += ' line-through';
        }
      }
    }

    if (!line && (underline === 'false' || strikethrough === 'false')) {
      line = 'none';
    }
    if (this.style) {
      this.style.textDecorationLine = line;
      this.style.textDecorationColor = color;
      this.style.textDecorationStyle = style;
    }
  }

  _handleFontSize() {
    if (this.hasAttribute('fontsize')) {
      const fontsize = this.getAttribute('fontsize');
      this.style.fontSize = `${fontsize}pt`;
    } else {
      this.style.fontSize = '';
    }
  }

  _handleFontFamily() {
    if (this.hasAttribute('fontfamily')) {
      const fontFamily = this.getAttribute('fontfamily');
      this.style.fontFamily = `"${fontFamily}"`;

      // validate font family
      const editorManager = EditorManager.getInstance();
      if (editorManager && fontFamily) {
        editorManager.validateFontFamily(fontFamily);
      }
    } else {
      this.style.fontFamily = '';
    }
  }

  _handleColor() {
    if (this.hasAttribute('color')) {
      let color = this.getAttribute('color');

      if (color?.includes('rgb')) {
        color = EditorDOMUtils.rgbToHex(color);
      } else if (!color?.includes('#')) {
        color = `#${color}`;
      }

      this.style.color = `var(--suggestionColor, ${color})`;
    } else {
      this.style.color = '';
    }
  }

  _handleHighlighColor() {
    if (this.hasAttribute('highlightcolor')) {
      const bg = this.getAttribute('highlightcolor');
      if (bg) {
        if (bg === 'false') {
          this.style.backgroundColor = 'rgba(0,0,0,0)';
        } else {
          let color = bg;
          if (bg.includes('rgb')) {
            color = EditorDOMUtils.rgbToHex(bg) ?? '';
          } else if (!bg.includes('#')) {
            color = `#${bg}`;
          }
          if (this.closest('comment-element') && color) {
            this.style.backgroundColor = `${color}66`; // 40% opacity
          } else {
            this.style.backgroundColor = color;
          }
        }
      }
    } else {
      this.style.backgroundColor = '';
    }
  }

  handleLineHeight() {
    if (this.Visualizer && this.Data && this.isConnected) {
      let fontFamily = this.getAttribute('fontfamily');
      let fontSize = this.getAttribute('fontsize');

      if (fontFamily || fontSize) {
        if (!fontFamily) {
          fontFamily = getComputedStyle(this).fontFamily;
        }

        if (!fontSize) {
          fontSize = getComputedStyle(this).fontSize;
        } else {
          fontSize = `${fontSize}pt`;
        }

        const block = EditorDOMUtils.closest(this, EditorDOMElements.BLOCK_TEXT_ELEMENTS);

        if (EditorDOMElements.isParagraphElement(block)) {
          let lineHeight: string | number | undefined = block.getStyleAttribute('lineHeight');
          if (!lineHeight) {
            if (block.styleId) {
              const style = this.Data.styles.documentStyles.style(block.styleId);

              if (style?.extendedP?.lh) {
                lineHeight = style.extendedP.lh;
              }
            }
          }

          if (lineHeight != null) {
            const metrics = this.Visualizer.fontFamilyHelper?.getTextMetrics(fontFamily, fontSize);

            let calculateLineHeight = lineHeight;
            if (metrics?.fontBoundingBoxAscent != null && metrics?.fontBoundingBoxDescent != null) {
              calculateLineHeight =
                (metrics.fontBoundingBoxAscent + metrics.fontBoundingBoxDescent) * +lineHeight;
              this.style.lineHeight = `${calculateLineHeight}px`;
            } else {
              this.style.lineHeight = `${calculateLineHeight}`;
            }
          } else {
            this.style.lineHeight = '';
          }
        }
      }
    }
  }

  isEqual(node: HTMLElement) {
    if (this.tagName === node.tagName) {
      let key: keyof typeof ATTRIBUTE_PARSE;
      for (let i = 0; i < ATTRIBUTES_LENGTH; i += 1) {
        key = ATTRIBUTES[i] as keyof typeof ATTRIBUTE_PARSE;
        if (compareAttribute(key, this.getAttribute(key), node.getAttribute(key)) !== 0) {
          return false;
        }
      }
      return true;
    }
    return false;
  }

  getAppliedStyles() {
    const styles: Styles = {
      fontFamily: '',
      fontSize: '',
      color: '',
      highlightColor: '',
      bold: false,
      italic: false,
      underline: false,
      strikethrough: false,
      superscript: false,
      subscript: false,
      vanish: false,
    };

    styles.fontFamily = this.getAttribute('fontfamily') ?? '';

    styles.fontSize = this.getAttribute('fontsize') ?? '';

    styles.color = this.getAttribute('color') ?? '';

    styles.highlightColor = this.getAttribute('highlightcolor') ?? '';

    styles.bold = parseBool(this.getAttribute('bold') ?? 'false');

    styles.italic = parseBool(this.getAttribute('italic') ?? 'false');

    styles.underline = parseBool(this.getAttribute('underline') ?? 'false');

    styles.strikethrough = parseBool(this.getAttribute('strikethrough') ?? 'false');

    styles.superscript = parseBool(this.getAttribute('superscript') ?? 'false');

    styles.subscript = parseBool(this.getAttribute('subscript') ?? 'false');

    styles.vanish = parseBool(this.getAttribute('vanish') ?? 'false');

    return styles;
  }

  get isVanish() {
    return this.hasAttribute('vanish') && this.getAttribute('vanish') === 'true';
  }

  asSpan() {
    const span = document.createElement('span');
    const styles = this.getAppliedStyles();
    span.style.fontFamily = styles.fontFamily;
    span.style.fontSize = styles.fontSize;
    span.style.color = styles.color || '';
    span.style.backgroundColor = styles.highlightColor || '';
    let pointer = span;
    if (styles.bold) {
      const b = document.createElement('b');
      pointer.appendChild(b);
      pointer = b;
    }
    if (styles.italic) {
      const i = document.createElement('i');
      pointer.appendChild(i);
      pointer = i;
    }
    if (styles.underline) {
      const u = document.createElement('u');
      pointer.appendChild(u);
      pointer = u;
    }
    if (styles.strikethrough) {
      const s = document.createElement('s');
      pointer.appendChild(s);
      pointer = s;
    }
    if (styles.superscript) {
      const sup = document.createElement('sup');
      pointer.appendChild(sup);
      pointer = sup;
    }
    if (styles.subscript) {
      const sub = document.createElement('sub');
      pointer.appendChild(sub);
      pointer = sub;
    }
    return span;
  }
}

if (!window.customElements.get(ELEMENTS.FormatElement.IDENTIFIER)) {
  window.customElements.define(ELEMENTS.FormatElement.IDENTIFIER, FormatElement);
}
