import { ELEMENTS, INDENT_TYPE, TEXT_ALIGN_PROPERTIES } from 'Editor/services/consts';
import { hexToRgb } from 'assets/colors';
import { ReduxInterface } from 'Editor/services';
import ActionContext from 'Editor/services/EditionManager/EditionModes/_Common/models/ActionContext';
import { ParseMapper } from 'Editor/services/Parsers';
import DOMNormalizer from 'Editor/services/DOMUtilities/DOMNormalizer/DOMNormalizer';
import EditorManager from 'Editor/services/EditorManager';
import AttributeApplier from './AttributeApplier';
import { isEqual, uniqWith } from 'lodash-es';
import StylesUtils from '../Utils/StylesUtils';
import {
  EditorRange,
  EditorSelectionUtils,
  SelectionFixer,
} from 'Editor/services/_Common/Selection';
import { parseMeasurement } from 'utils';
import { Logger } from '_common/services';
import { EditorDOMElements, EditorDOMUtils } from 'Editor/services/_Common/DOM';
import {
  BaseBlockElement,
  BaseViewElement,
  CommentElement,
  FigureElement,
  ParagraphElement,
  TableElement,
  TemporaryCommentElement,
  TrackDeleteElement,
  TrackInsertElement,
} from 'Editor/services/VisualizerManager/Views/ViewBuilders';

type NodeAppliedStylesType = {
  fontFamily?: string | null;
  fontSize?: string | null;
  color?: string | null;
  highlightColor?: string | null;
  bold?: string | null;
  italic?: string | null;
  underline?: string | null;
  strikethrough?: string | null;
  superscript?: string | null;
  subscript?: string | null;
  vanish?: string | null;
};

export class InlineStyles {
  private editorContext: Editor.Context;
  private stylesContext: Editor.Styles.Context;

  private stylesToApply: {
    [index: string]: AttributeApplier;
  } = {};
  private stylesToRemove: any = {};
  private timeouts: {
    [index: string]: NodeJS.Timeout;
  } = {};

  skipNextCheckStyles: boolean = false;
  currentStyles: any = {};

  static BOOLEAN_ATTRIBUTES: Partial<Editor.Styles.Styles>[] = [
    StylesUtils.STYLES.BOLD,
    StylesUtils.STYLES.ITALIC,
  ];

  static SINGLE_STATE_ATTRIBUTES: Partial<Editor.Styles.Styles>[] = [
    StylesUtils.STYLES.STRIKETHROUGH,
    StylesUtils.STYLES.SUPERSCRIPT,
    StylesUtils.STYLES.SUBSCRIPT,
    StylesUtils.STYLES.UNDERLINE,
    StylesUtils.STYLES.VANISH,
  ];

  static EXCLUSIVE_ATTRIBUTES: { [index in Editor.Styles.Styles]?: Editor.Styles.Styles } = {
    [StylesUtils.STYLES.SUBSCRIPT]: StylesUtils.STYLES.SUPERSCRIPT,
    [StylesUtils.STYLES.SUPERSCRIPT]: StylesUtils.STYLES.SUBSCRIPT,
  };

  static extractAppliedStylesFromElement(node: HTMLElement) {
    const styles: NodeAppliedStylesType = {};
    if (node.nodeType !== Node.TEXT_NODE) {
      if (node.getAttribute('fontfamily')) {
        styles.fontFamily = node.getAttribute('fontfamily');
      }
      if (node.getAttribute('fontsize')) {
        styles.fontSize = node.getAttribute('fontsize');
      }
      if (node.getAttribute('color')) {
        styles.color = node.getAttribute('color');
      }
      if (node.getAttribute('highlightColor')) {
        styles.highlightColor = node.getAttribute('highlightColor');
      }
      if (node.getAttribute('bold')) {
        styles.bold = node.getAttribute('bold');
      }
      if (node.getAttribute('italic')) {
        styles.italic = node.getAttribute('italic');
      }
      if (node.getAttribute('underline')) {
        styles.underline = node.getAttribute('underline');
      }
      if (node.getAttribute('strikethrough')) {
        styles.strikethrough = node.getAttribute('strikethrough');
      }
      if (node.getAttribute('superscript')) {
        styles.superscript = node.getAttribute('superscript');
      }
      if (node.getAttribute('subscript')) {
        styles.subscript = node.getAttribute('subscript');
      }
      if (node.getAttribute('vanish')) {
        styles.vanish = node.getAttribute('vanish');
      }
    }
    return styles;
  }

  constructor(editorContext: Editor.Context, stylesContext: Editor.Styles.Context) {
    this.editorContext = editorContext;
    this.stylesContext = stylesContext;
  }

  start() {}

  destroy() {}

  buildAttributeApplier(attribute: Editor.Styles.Styles, value: string | boolean) {
    if (attribute != null && value != null && this.editorContext.visualizerManager != null) {
      return new AttributeApplier(
        {
          pageContext: this.editorContext.documentContainer,
          selectionManager: this.editorContext.selectionManager,
          visualizerManager: this.editorContext.visualizerManager,
        },
        {
          elementTagName: ELEMENTS.FormatElement.TAG,
          attributeName: attribute,
          attributeValue: value,
          attributeGroup: StylesUtils.INLINE_STYLES_LIST,
          stylableElements: EditorDOMElements.INLINE_NON_STYLABLE_ELEMENTS,
          useExistingElements: true,
        },
      );
    }
    return null;
  }

  private resetStyles() {
    this.currentStyles = {};
    this.stylesToApply = {};
    this.stylesToRemove = [];
  }

  /**
   * Apply style attribute to non text elements
   * @param {*} actionContext
   * @param {*} element
   * @param {*} elementRange
   * @param {*} styleApplier
   * @deprecated
   */
   
  private applyStyleAttributeToBlockElement(
    actionContext: Editor.Edition.OldActionContext,
    element: Editor.Elements.BaseViewElement,
    elementRange: Editor.Selection.EditorRange,
    styleApplier: AttributeApplier,
  ) {
    if (
      (element instanceof BaseBlockElement || element instanceof TableElement) &&
      StylesUtils.ALLOWED_BLOCK_ATTRIBUTES_BY_ELEMENT[element.tag]?.includes(
        styleApplier.attributeName,
      )
    ) {
      const exclusiveAttribute = InlineStyles.EXCLUSIVE_ATTRIBUTES[styleApplier.attributeName];
      if (exclusiveAttribute) {
        // remove exclusive styles
        element.removeStyleAttribute?.(exclusiveAttribute);
      }

      if (InlineStyles.SINGLE_STATE_ATTRIBUTES.includes(styleApplier.attributeName)) {
        // remove attribute
        element.removeStyleAttribute?.(styleApplier.attributeName);

        if (styleApplier.attributeValue === true) {
          element.addStyleAttribute?.(styleApplier.attributeName, styleApplier.attributeValue);
        }
      } else {
        element.addStyleAttribute?.(styleApplier.attributeName, styleApplier.attributeValue);
      }

      actionContext.addChangeUpdatedNode(element);
    }
  }

  private cleanBlockProperties(
    actionContext: Editor.Edition.OldActionContext,
    block: Editor.Elements.BaseViewElement,
  ) {
    if (StylesUtils.STYLABLE_BLOCK_ELEMENTS.includes(block.tagName)) {
      // clean other styles
      if (EditorDOMUtils.isClosestTextElementEditable(block)) {
        block.removeAttribute('style');
        block.removeAttribute('data-background-color');

        if (
          this.editorContext.DataManager?.numbering.isListElement(block.id) &&
          this.editorContext.DataManager?.numbering.getListType(block.id) === 'simple'
        ) {
          this.editorContext.DataManager.numbering.removeBlocksFromList([block.id]);
          actionContext.jsonChanges = true;
        }
      }

      // @ts-expect-error
      if (block.getStyleAttributes && block.removeStyleAttributes) {
        // @ts-expect-error
        block.removeStyleAttributes(Object.keys(block.getStyleAttributes()));
      }

      actionContext.addChangeUpdatedNode(block);
    }
  }

  updateStyleValues(styles: any) {
    // fix "boolean strings"
    if (styles.bold != null) {
      styles.bold = styles.bold === true || styles.bold === 'true';
    }

    if (styles.italic != null) {
      styles.italic = styles.italic === true || styles.italic === 'true';
    }

    this.currentStyles = {
      ...this.currentStyles,
      ...styles,
    };

    if (this.timeouts.updateStyleValues) {
      clearTimeout(this.timeouts.updateStyleValues);
    }

    this.timeouts.updateStyleValues = setTimeout(() => {
      ReduxInterface.updateToolbarValues(this.currentStyles);
    }, 50);
  }

  getIndentationValuesFromBlock(block: HTMLElement) {
    let indentation: {
      leftIndentation: number | null;
      rightIndentation: number | null;
      specialIndent: string;
      specialIndentValue: number | null;
    } | null = null;
    const style = this.editorContext.DataManager?.styles?.getDocumentStyleFromId(
      block.dataset.styleId as string,
    );

    // get list defenition if exist
    let listLevelDefenition;
    if (this.editorContext.DataManager?.numbering?.isListElement(block.id)) {
      listLevelDefenition = this.editorContext.DataManager?.numbering?.getLevelDefinitionFromBlock(
        block.id,
      );
    }

    if (style) {
      indentation = {
        leftIndentation: 0,
        rightIndentation: 0,
        specialIndent: 'none',
        specialIndentValue: 0,
      };

      // Special indentation type
      if (block.dataset.specialIndent != null) {
        indentation.specialIndent = block.dataset.specialIndent;
      } else if (
        listLevelDefenition &&
        listLevelDefenition.sp_ind &&
        listLevelDefenition?.sp_ind?.t != null
      ) {
        indentation.specialIndent =
          //@ts-expect-error temporary fix for legacy issues
          listLevelDefenition.sp_ind.value?.t || listLevelDefenition.sp_ind.t;
      } else if (style && style.p && style.p.sp_ind && style.p.sp_ind.t != null) {
        indentation.specialIndent = style.p.sp_ind.t;
      }

      // Special indentation value
      if (block.dataset.specialIndentValue != null) {
        indentation.specialIndentValue = parseMeasurement(block.dataset.specialIndentValue, 'cm', {
          defaultUnit: 'pt',
        });
      } else if (
        listLevelDefenition &&
        listLevelDefenition.sp_ind &&
        listLevelDefenition.sp_ind.v
      ) {
        indentation.specialIndentValue = parseMeasurement(
          listLevelDefenition.sp_ind.v.toString(),
          'cm',
          {
            defaultUnit: 'pt',
          },
        );
      } else if (style && style.p && style.p.sp_ind && style.p.sp_ind.v) {
        indentation.specialIndentValue = parseMeasurement(`${style.p.sp_ind.v}`, 'cm', {
          defaultUnit: 'pt',
        });
      }

      // Left indentation
      if (block.dataset.leftIndentation != null) {
        indentation.leftIndentation = parseMeasurement(block.dataset.leftIndentation, 'cm', {
          defaultUnit: 'pt',
        });
      } else if (listLevelDefenition && listLevelDefenition.ind?.l) {
        indentation.leftIndentation = parseMeasurement(listLevelDefenition.ind.l.toString(), 'cm', {
          defaultUnit: 'pt',
        });
      } else if (style && style.p && style.p.ind && style.p.ind.l) {
        indentation.leftIndentation = parseMeasurement(`${style.p.ind.l}`, 'cm', {
          defaultUnit: 'pt',
        });
      }

      // decrement indentation if special indent exist
      if (
        indentation.specialIndentValue != null &&
        indentation.specialIndent === INDENT_TYPE.HANGING
      ) {
        if (indentation.leftIndentation != null) {
          indentation.leftIndentation -= indentation.specialIndentValue;
        } else {
          indentation.leftIndentation = indentation.specialIndentValue;
        }
      }

      // Right indentation
      if (block.dataset.rightIndentation != null) {
        indentation.rightIndentation = parseMeasurement(block.dataset.rightIndentation, 'cm', {
          defaultUnit: 'pt',
        });
      } else if (listLevelDefenition && listLevelDefenition.ind?.r) {
        indentation.rightIndentation = parseMeasurement(
          listLevelDefenition.ind.r.toString(),
          'cm',
          {
            defaultUnit: 'pt',
          },
        );
      } else if (style && style.p && style.p.ind && style.p.ind.r) {
        indentation.rightIndentation = parseMeasurement(`${style.p.ind.r}`, 'cm', {
          defaultUnit: 'pt',
        });
      }
    }

    return indentation;
  }

  /**
   * check inline style values from selected nodes
   */
  checkInlineStylesStatusOnSelection() {
    if (!this.skipNextCheckStyles) {
      const lastRange = EditorSelectionUtils.getRange();
      if (lastRange) {
        const nodes = lastRange?.getNodes(
          [Node.TEXT_NODE],
          (node: Node) =>
            node.parentNode != null &&
            (EditorDOMElements.INLINE_ELEMENTS.includes(node.parentNode.nodeName) ||
              StylesUtils.STYLABLE_BLOCK_ELEMENTS.includes(node.parentNode.nodeName)),
        );

        if (nodes?.length === 0) {
          const closest = EditorDOMUtils.closest(lastRange?.commonAncestorContainer, [
            ELEMENTS.FormatElement.TAG,
            ...StylesUtils.STYLABLE_BLOCK_ELEMENTS,
          ]);
          if (closest) {
            nodes.push(closest);
          }
        }

        // let contentStructure = null;
        let fontFamily: any = null;
        let fontSize: any = null;
        let color: any = null;
        let highlightColor: any = null;
        let paragraphColor: any = null;

        let bold: any = null;
        let italic: any = null;
        let underline: any = null;

        let strikethrough: any = null;
        let superscript: any = null;
        let subscript: any = null;

        let vanish: any = null;

        let selectedList: any = null;

        let lineHeight: any = null;
        let spaceBefore: any = null;
        let spaceAfter: any = null;
        let autoSpaceBefore: any = null;
        let autoSpaceAfter: any = null;
        let widowControl: any = null;
        let keepNext: any = null;
        let keepLines: any = null;
        let contextualSpacing: any = null;
        let pageBreakBefore: any = null;
        let textAlignment: any = null;

        let indentation = null;

        const blocks: HTMLElement[] = [];
        nodes?.forEach((node: Node) => {
          if (
            node &&
            this.editorContext.documentContainer &&
            EditorDOMUtils.parentContainsNode(this.editorContext.documentContainer, node)
          ) {
            if (node.nodeType === Node.TEXT_NODE) {
              node = node.parentNode as HTMLElement;
            }

            const extractedStyles: any = StylesUtils.getStylesAppliedToNode(
              node,
              [
                ...StylesUtils.INLINE_STYLES_LIST,
                'paragraphColor',
                StylesUtils.STYLES.LINEHEIGHT,
                StylesUtils.STYLES.SPACEBEFORE,
                StylesUtils.STYLES.SPACEAFTER,
                StylesUtils.STYLES.ASB,
                StylesUtils.STYLES.ASA,
                StylesUtils.STYLES.WC,
                StylesUtils.STYLES.KN,
                StylesUtils.STYLES.KL,
                StylesUtils.STYLES.CTS,
                StylesUtils.STYLES.PBB,
                StylesUtils.STYLES.ALIGNMENT,
              ],
              { dataManager: this.editorContext.DataManager },
            );

            if (color === null) {
              color = extractedStyles.color;
            } else if (color !== extractedStyles.color) {
              color = '';
            }

            if (fontSize === null) {
              fontSize = extractedStyles.fontSize;
            } else if (fontSize !== extractedStyles.fontSize) {
              fontSize = '';
            }

            if (fontFamily === null) {
              fontFamily = extractedStyles.fontFamily;
            } else if (fontFamily !== extractedStyles.fontFamily) {
              fontFamily = '';
            }

            if (bold === null) {
              bold = extractedStyles.bold;
            } else if (bold !== extractedStyles.bold) {
              bold = false;
            }

            if (highlightColor === null) {
              highlightColor = extractedStyles.highlightColor;
            } else if (highlightColor !== extractedStyles.highlightColor) {
              highlightColor = '';
            }

            if (paragraphColor === null) {
              paragraphColor = extractedStyles.paragraphColor;
            } else if (paragraphColor !== extractedStyles.paragraphColor) {
              paragraphColor = '';
            }

            if (italic === null) {
              italic = extractedStyles.italic;
            } else if (italic !== extractedStyles.italic) {
              italic = false;
            }

            if (underline === null) {
              underline = extractedStyles.underline;
            } else if (underline !== extractedStyles.underline) {
              underline = false;
            }

            if (strikethrough === null) {
              strikethrough = extractedStyles.strikethrough;
            } else if (strikethrough !== extractedStyles.strikethrough) {
              strikethrough = false;
            }

            if (superscript === null) {
              superscript = extractedStyles.superscript;
            } else if (superscript !== extractedStyles.superscript) {
              superscript = false;
            }

            if (subscript === null) {
              subscript = extractedStyles.subscript;
            } else if (subscript !== extractedStyles.subscript) {
              subscript = false;
            }

            if (vanish === null) {
              vanish = extractedStyles.vanish;
            } else if (vanish !== extractedStyles.vanish) {
              vanish = false;
            }

            if (lineHeight === null) {
              lineHeight = extractedStyles[StylesUtils.STYLES.LINEHEIGHT];
            } else if (lineHeight !== extractedStyles[StylesUtils.STYLES.LINEHEIGHT]) {
              lineHeight = '';
            }

            if (spaceBefore === null) {
              spaceBefore = extractedStyles[StylesUtils.STYLES.SPACEBEFORE];
            } else if (spaceBefore !== extractedStyles[StylesUtils.STYLES.SPACEBEFORE]) {
              spaceBefore = '';
            }

            if (spaceAfter === null) {
              spaceAfter = extractedStyles[StylesUtils.STYLES.SPACEAFTER];
            } else if (spaceAfter !== extractedStyles[StylesUtils.STYLES.SPACEAFTER]) {
              spaceAfter = '';
            }

            if (autoSpaceBefore === null) {
              autoSpaceBefore = extractedStyles[StylesUtils.STYLES.ASB];
            } else if (autoSpaceBefore !== extractedStyles[StylesUtils.STYLES.ASB]) {
              autoSpaceBefore = false;
            }

            if (autoSpaceAfter === null) {
              autoSpaceAfter = extractedStyles[StylesUtils.STYLES.ASA];
            } else if (autoSpaceAfter !== extractedStyles[StylesUtils.STYLES.ASA]) {
              autoSpaceAfter = false;
            }

            if (widowControl === null) {
              widowControl = extractedStyles[StylesUtils.STYLES.WC];
            } else if (widowControl !== extractedStyles[StylesUtils.STYLES.WC]) {
              widowControl = false;
            }

            if (keepNext === null) {
              keepNext = extractedStyles[StylesUtils.STYLES.KN];
            } else if (keepNext !== extractedStyles[StylesUtils.STYLES.KN]) {
              keepNext = false;
            }

            if (keepLines === null) {
              keepLines = extractedStyles[StylesUtils.STYLES.KL];
            } else if (keepLines !== extractedStyles[StylesUtils.STYLES.KL]) {
              keepLines = false;
            }

            if (contextualSpacing === null) {
              contextualSpacing = extractedStyles[StylesUtils.STYLES.CTS];
            } else if (contextualSpacing !== extractedStyles[StylesUtils.STYLES.CTS]) {
              contextualSpacing = false;
            }

            if (pageBreakBefore === null) {
              pageBreakBefore = extractedStyles[StylesUtils.STYLES.PBB];
            } else if (pageBreakBefore !== extractedStyles[StylesUtils.STYLES.PBB]) {
              pageBreakBefore = false;
            }
            const alignment = ParseMapper.alignmentMapper.parse(
              extractedStyles[StylesUtils.STYLES.ALIGNMENT],
            );
            if (textAlignment === null) {
              textAlignment = alignment;
            } else if (textAlignment !== alignment) {
              textAlignment = '';
            }

            let block = node;
            while (
              EditorDOMElements.isInlineNode(block) &&
              block.parentNode !== this.editorContext.documentContainer
            ) {
              block = block.parentNode as HTMLElement;
            }
            if (block instanceof HTMLElement && !blocks.includes(block)) {
              blocks.push(block);
            }

            // const list = DOMUtils.closest(block, 'UL,OL');
            // const tag = list ? list.tagName : block.tagName;
            // if (contentStructure === null) {
            //   contentStructure = tag;
            // } else if (contentStructure !== tag) {
            //   contentStructure = '';
            // }
          }
        });

        if (blocks.length === 0) {
          const closest = EditorDOMUtils.closest(
            lastRange.commonAncestorContainer,
            EditorDOMElements.BLOCK_TEXT_ELEMENTS,
          );
          if (closest instanceof HTMLElement) {
            blocks.push(closest);
          }
        }

        try {
          if (blocks.length > 0) {
            let firstStyleId;
            for (let index = 0; index < blocks.length; index++) {
              const firstElement = blocks[0];
              const element = blocks[index];

              if (indentation == null) {
                indentation = this.getIndentationValuesFromBlock(element);
              }

              if (this.editorContext.DataManager?.numbering.isListElement(element.id)) {
                const firstListId = this.editorContext.DataManager.numbering.getListIdFromBlock(
                  firstElement.id,
                );
                const listId = this.editorContext.DataManager.numbering.getListIdFromBlock(
                  element.id,
                );

                if (!listId || !this.editorContext.DataManager.numbering.listExists(listId)) {
                  selectedList = '';
                  break;
                }
                const styleId = this.editorContext.DataManager.numbering.getStyleIdForList(listId);
                if (!firstStyleId || firstStyleId === styleId) {
                  if (firstListId) {
                    firstStyleId =
                      this.editorContext.DataManager.numbering.getStyleIdForList(firstListId);
                  }
                  selectedList = styleId;
                } else {
                  selectedList = '';
                  break;
                }
              } else {
                selectedList = '';
                break;
              }
            }
          }
        } catch (error) {
          // Logger.captureException(error);
        }

        // reset styles to apply
        this.resetStyles();

        this.updateStyleValues({
          fontFamily: fontFamily && fontFamily.toLowerCase().replace(/"/g, ''),
          fontSize,
          color,
          highlightColor,
          paragraphColor,
          bold,
          italic,
          underline,
          strikethrough,
          superscript,
          subscript,
          vanish,
          selectedList,
          lineHeight,
          spaceBefore,
          spaceAfter,
          autoSpaceBefore,
          autoSpaceAfter,
          widowControl,
          keepNext,
          keepLines,
          contextualSpacing,
          pageBreakBefore,
          alignment: textAlignment,
          indentation,
        });
      }
    } else {
      this.skipNextCheckStyles = false;
    }
  }

  /**
   * add a single applier to pending styles
   * @param {Object} applier attribute applier
   */
  addApplierToPendingStyles(applier: AttributeApplier, override: boolean = false) {
    if (
      StylesUtils.INLINE_STYLES_LIST.includes(applier.attributeName) &&
      (!this.stylesToApply[applier.attributeName] || override)
    ) {
      if (InlineStyles.BOOLEAN_ATTRIBUTES.includes(applier.attributeName)) {
        this.stylesToApply[applier.attributeName] = applier;

        this.updateStyleValues({
          [applier.attributeName]: applier.attributeValue === true,
        });
        // }
      } else {
        this.stylesToApply[applier.attributeName] = applier;

        this.updateStyleValues({
          [applier.attributeName]: applier.attributeValue,
        });
      }
    }
  }

  /**
   * add an array of appliers to pending styles
   * @param {Array} appliers
   */
  addArrayAppliersToPendingStyles(
    options: {
      appliers?: AttributeApplier[];
      skipNextCheckStyles?: boolean;
      resetStyles?: boolean;
    } = {},
  ) {
    const { appliers = [], skipNextCheckStyles = false, resetStyles = true } = options;
    // reset styles to apply
    if (resetStyles) {
      this.resetStyles();
    }
    this.skipNextCheckStyles = skipNextCheckStyles;
    if (Array.isArray(appliers)) {
      let i;
      for (i = 0; i < appliers.length; i++) {
        this.addApplierToPendingStyles(appliers[i], resetStyles);
      }
    }
  }

  /**
   * add an atribute to be removed in the next aplication of styles
   * @param {string} attribute
   */
  addAtributeToRemove(attribute: string) {
    this.stylesToRemove.push(attribute);

    this.updateStyleValues({
      [attribute]: undefined,
    });
  }

  /**
   * check if ther is any pending actions to apply
   */
  areStylesToApply() {
    return (
      !!(this.stylesToApply && Object.keys(this.stylesToApply).length > 0) ||
      !!(this.stylesToRemove && this.stylesToRemove.length > 0)
    );
  }

  /**
   * get style appliers from selection
   */
  getStyleAppliersFromSelection(appliersToExtract: string[]) {
    let nodes: Node[] = EditorSelectionUtils.getSelectedNodes() || [];

    // filter nodes
    nodes = Array.from(nodes).reduce((filteredNodes: Node[], node: Node) => {
      let nodeToAdd = node;

      if (this.editorContext.documentContainer) {
        while (
          nodeToAdd.parentNode !== this.editorContext.documentContainer &&
          EditorDOMUtils.parentContainsNode(this.editorContext.documentContainer, nodeToAdd)
        ) {
          if (EditorDOMElements.isFormatElement(nodeToAdd) && !filteredNodes.includes(nodeToAdd)) {
            filteredNodes.push(nodeToAdd);
          }

          nodeToAdd = nodeToAdd.parentNode as HTMLElement;
        }
      }

      return filteredNodes;
    }, []);

    // get styles from filtered nodes
    return Array.from(nodes).reduce((appliers: AttributeApplier[], node) => {
      appliers.push(...this.getStyleAppliersFromElement(node, appliersToExtract));
      return appliers;
    }, []);
  }

  /**
   * get style appliers from format-element
   * @param {Node} nodeElement
   */
  getStyleAppliersFromElement(
    nodeElement: Node,
    appliersToExtract: string[] = StylesUtils.INLINE_STYLES_LIST,
  ) {
    const appliers: AttributeApplier[] = [];
    if (nodeElement && EditorDOMElements.isFormatElement(nodeElement)) {
      const styles = InlineStyles.extractAppliedStylesFromElement(nodeElement);

      let i;
      let attributeKey: keyof NodeAppliedStylesType;
      const styleKeys = Object.keys(styles);
      for (i = 0; i < styleKeys.length; i++) {
        attributeKey = styleKeys[i] as keyof NodeAppliedStylesType;
        const attributeValue = styles[attributeKey];
        if (attributeValue != null && appliersToExtract.includes(attributeKey)) {
          const applier = this.buildAttributeApplier(attributeKey, attributeValue);
          if (applier) {
            appliers.push(applier);
          }
        }
      }
      return appliers;
    }
    return appliers;
  }

  undoStyleAppliersFromRange(
    actionContext: Editor.Edition.OldActionContext,
    range: Editor.Selection.EditorRange | undefined = EditorSelectionUtils.getRange(),
    appliersToExtract: string[] = StylesUtils.INLINE_STYLES_LIST,
    restoreSelection: boolean = true,
  ) {
    if (range) {
      const rangeNodes = range.getNodes();

      const blockNodes: Node[] = [];
      const filteredNodes: Node[] = [];

      // filter nodes
      let k;
      for (k = 0; k < rangeNodes.length; k++) {
        let nodeToAdd = rangeNodes[k];

        if (nodeToAdd) {
          if (
            nodeToAdd.parentNode === this.editorContext.documentContainer ||
            EditorDOMElements.isNodeContainerElement(nodeToAdd.parentNode)
          ) {
            if (!blockNodes.includes(nodeToAdd)) {
              blockNodes.push(nodeToAdd);
            }
          } else if (this.editorContext.documentContainer) {
            while (
              nodeToAdd !== this.editorContext.documentContainer &&
              !EditorDOMElements.isNodeContainerElement(nodeToAdd.parentNode) &&
              EditorDOMUtils.parentContainsNode(this.editorContext.documentContainer, nodeToAdd)
            ) {
              if (
                nodeToAdd.nodeType !== Node.TEXT_NODE &&
                nodeToAdd.nodeName === 'FORMAT-ELEMENT' &&
                !filteredNodes.includes(nodeToAdd)
              ) {
                filteredNodes.push(nodeToAdd);
              }

              if (
                (nodeToAdd.parentNode === this.editorContext.documentContainer ||
                  EditorDOMElements.isNodeContainerElement(nodeToAdd.parentNode)) &&
                !blockNodes.includes(nodeToAdd)
              ) {
                blockNodes.push(nodeToAdd);
              }

              nodeToAdd = nodeToAdd.parentNode as Node;
            }
          }
        }
      }

      // get styles from filtered nodes and undo them
      let i;
      let z;
      let appliers;
      for (i = 0; i < filteredNodes.length; i++) {
        actionContext.addChangeUpdatedNode(filteredNodes[i]);

        appliers = this.getStyleAppliersFromElement(filteredNodes[i], appliersToExtract);
        for (z = 0; z < appliers.length; z++) {
          range = appliers[z].undoToRange(range);
        }
      }

      let savedMarkers;
      if (restoreSelection) {
        savedMarkers = range.saveRange();
      }

      // normalize childreen because some format elements get without id
      let j = 0;
      for (j = 0; j < blockNodes.length; j++) {
        if (!DOMNormalizer.isAlreadyNormalized(blockNodes[j])) {
          DOMNormalizer.normalizeTree(blockNodes[j], (blockNodes[j].parentNode as HTMLElement).id);
        }
      }

      if (restoreSelection && savedMarkers) {
        range.restoreRange(savedMarkers);
        EditorSelectionUtils.applyRangeToSelection(range);
      }
    }
  }

  /**
   * Apply style atributo to text elements
   * @param {*} actionContext
   * @param {*} textBlock
   * @param {*} textRange
   * @param {*} styleApplier
   * @deprecated
   */
  private applyStyleAttributeToTextElement(
    actionContext: Editor.Edition.OldActionContext,
    textBlock: Editor.Elements.BaseViewElement,
    textRange: Editor.Selection.EditorRange,
    styleApplier: AttributeApplier,
  ) {
    if (EditorDOMUtils.isClosestTextElementEditable(textBlock)) {
      if (
        EditorSelectionUtils.isSelectionAtStart(textBlock, 'start', textRange) &&
        EditorSelectionUtils.isSelectionAtEnd(textBlock, 'end', textRange)
      ) {
        // if whole block is selected
        // toggle style on paragraph
        this.applyStyleAttributeToBlockElement(actionContext, textBlock, textRange, styleApplier);
      }

      // check if exclusive styles are applied
      // this means that we are applying a super or sub then the inverse must be removed
      const exclusiveAttribute = InlineStyles.EXCLUSIVE_ATTRIBUTES[styleApplier.attributeName];
      if (exclusiveAttribute) {
        const exclusiveApplier = this.buildAttributeApplier(exclusiveAttribute, true);
        if (exclusiveApplier) {
          textRange = exclusiveApplier.undoToRange(textRange);
        }
      }

      if (InlineStyles.BOOLEAN_ATTRIBUTES.includes(styleApplier.attributeName)) {
        const booleanApplier = this.buildAttributeApplier(
          styleApplier.attributeName,
          !styleApplier.attributeValue,
        );

        if (booleanApplier) {
          textRange = booleanApplier.undoToRange(textRange);

          // check if is necessary to apply
          const stylesApplied = StylesUtils.getStylesAppliedToNode(
            textRange.commonAncestorContainer,
            InlineStyles.BOOLEAN_ATTRIBUTES,
          );
          if (stylesApplied[styleApplier.attributeName] !== styleApplier.attributeValue) {
            textRange = styleApplier.applyToRange(textRange);
          }
        }
      } else if (InlineStyles.SINGLE_STATE_ATTRIBUTES.includes(styleApplier.attributeName)) {
        const applier = this.buildAttributeApplier(styleApplier.attributeName, true);
        if (applier) {
          applier.undoToRange(textRange);
        }

        if (styleApplier.attributeValue === true) {
          textRange = styleApplier.applyToRange(textRange);
        }
      } else {
        textRange = styleApplier.toggleRange(textRange);
      }

      // normalize tree becasue some formats may miss an id
      // DOMNormalizer.normalizeTree(textBlock, textBlock.parentNode.id);

      actionContext.addChangeUpdatedNode(textBlock);
    }
  }

  /**
   * @deprecated
   * toggle pending styles that were previously set
   */
  togglePendingStyles(range = EditorSelectionUtils.getRange()) {
    if (this.areStylesToApply()) {
      this.editorContext.visualizerManager?.selection.stopSelectionTracker();
      let savedMarkers;

      if (range) {
        try {
          const actionContext = new ActionContext();

          SelectionFixer.normalizeTextSelection(range, {
            forceTextAsWrap: true,
          });

          savedMarkers = range.saveRange();

          range = EditorSelectionUtils.getRangeBasedOnSavedMarkers(savedMarkers, false);

          const stylableRanges: any[] =
            EditorRange.splitRangeByBlocks(range, StylesUtils.STYLABLE_BLOCK_ELEMENTS) || [];

          Object.keys(this.stylesToApply).forEach((style) => {
            const styleApplier = this.stylesToApply[style];

            if (styleApplier) {
              let i;
              for (i = 0; i < stylableRanges.length; i++) {
                const blockNode = stylableRanges[i].block;
                if (
                  blockNode instanceof BaseViewElement &&
                  (StylesUtils.ALLOWED_BLOCK_ATTRIBUTES_BY_ELEMENT[blockNode.tag]?.includes(
                    styleApplier.attributeName,
                  ) ||
                    StylesUtils.ALLOWED_INLINE_ATTRIBUTES_BY_ELEMENT[blockNode.tag]?.includes(
                      styleApplier.attributeName,
                    ))
                ) {
                  if (EditorDOMElements.BLOCK_TEXT_ELEMENTS.includes(blockNode.tagName)) {
                    // apply style to text elements
                    this.applyStyleAttributeToTextElement(
                      actionContext,
                      blockNode,
                      stylableRanges[i].range,
                      styleApplier,
                    );
                  } else {
                    // apply style to other elements
                    this.applyStyleAttributeToBlockElement(
                      actionContext,
                      blockNode,
                      stylableRanges[i].range,
                      styleApplier,
                    );
                  }
                }
              }

              if (
                styleApplier.attributeName === 'fontFamily' &&
                typeof styleApplier.attributeValue === 'string'
              ) {
                this.editorContext.visualizerManager
                  ?.getFontFamilyHelper()
                  ?.validateFontFamily(styleApplier.attributeValue);
              }

              this.updateStyleValues({
                [styleApplier.attributeName]: styleApplier.attributeValue,
              });
            }
          });

          // verify if there are styles to remove
          if (this.stylesToRemove.length > 0) {
            let i;
            for (i = 0; i < stylableRanges.length; i++) {
              if (EditorDOMUtils.isClosestTextElementEditable(stylableRanges[i].block)) {
                this.undoStyleAppliersFromRange(
                  actionContext,
                  stylableRanges[i].range,
                  this.stylesToRemove,
                  false,
                );
              }
            }
          }

          if (range && savedMarkers) {
            range.restoreRange(savedMarkers);

            EditorSelectionUtils.applyRangeToSelection(range);
          }

          this.editorContext.visualizerManager?.selection.triggerSelectionChanged();
          //@ts-expect-error
          this.editorContext.changeTracker?.saveActionChanges(actionContext);
        } catch (e) {
          // Logger.captureException(e);
          if (savedMarkers) {
            const range = EditorSelectionUtils.getRange();
            if (range) {
              range.restoreRange(savedMarkers);
              EditorSelectionUtils.applyRangeToSelection(range);
            }
          }
        } finally {
          this.editorContext.visualizerManager?.selection.debounceStartSelectionTracker();
        }
      }
    }

    this.stylesToApply = {};
    this.stylesToRemove = [];
  }

  /**
   * @deprecated
   * toogle a format-element atribute
   * @param {*} attribute
   * @param {*} value
   */
  toggleStyleAttribute(
    attribute: Editor.Styles.Styles,
    value: string | boolean = true,
    options: any = {},
  ) {
    let { askUser = true, toRemove = false, scrollToMarker = true } = options;

    if (askUser && !this.stylesContext.askUserAboutThis?.()) {
      return;
    }

    if (this.editorContext.navigationManager?.isMarkerRendered()) {
      this.editorContext.visualizerManager?.selection.stopSelectionTracker();

      try {
        if (scrollToMarker) {
          // restore and scroll into selection if needed
          this.editorContext.navigationManager.scrollIntoSelection();
        }

        EditorManager.getInstance().removePasteOptions();

        let rangeToHandle = EditorSelectionUtils.getRange();

        // create atribute applier and addit to array
        if (rangeToHandle && StylesUtils.INLINE_STYLES_LIST.includes(attribute)) {
          if (toRemove) {
            this.addAtributeToRemove(attribute);
          } else if (value != null) {
            const applier = this.buildAttributeApplier(attribute, value);
            applier && this.addApplierToPendingStyles(applier, true);
          }

          if (rangeToHandle.collapsed) {
            const closestTable = EditorDOMUtils.closest(rangeToHandle.commonAncestorContainer, [
              ELEMENTS.TableElement.TAG,
            ]);

            let blockElement;
            let selectedCells;
            if (closestTable) {
              //@ts-expect-error
              selectedCells = closestTable.getSelectedCells();
              const closestCell = EditorDOMUtils.closest(rangeToHandle.commonAncestorContainer, [
                ELEMENTS.TableCellElement.TAG,
              ]);
              blockElement = EditorDOMUtils.findFirstLevelChildNode(
                closestCell,
                rangeToHandle.commonAncestorContainer,
              );
            } else if (this.editorContext.documentContainer) {
              blockElement = EditorDOMUtils.findFirstLevelChildNode(
                this.editorContext.documentContainer,
                rangeToHandle.commonAncestorContainer,
              );
            }
            if (
              selectedCells?.length > 0 ||
              (blockElement instanceof Element &&
                StylesUtils.STYLABLE_BLOCK_ELEMENTS.includes(blockElement.tagName) &&
                !EditorDOMUtils.isClosestTextElementEditable(blockElement))
            ) {
              this.togglePendingStyles(rangeToHandle);
            }
          } else {
            this.togglePendingStyles(rangeToHandle);
          }
        } else {
          throw new Error(`Invalid style to apply! - ${attribute}`);
        }

        // WARN do not trigger selection changed
        // this.editorContext.visualizerManager?.selection.triggerSelectionChanged();
      } catch (error) {
        this.editorContext.DataManager?.selection?.restore();
      } finally {
        this.editorContext.visualizerManager?.selection.debounceStartSelectionTracker();
      }
    }
  }

  /**
   * @deprecated
   */
  alignCurrentSelection(params: Editor.Elements.TextAlignProperties) {
    if (!this.stylesContext.askUserAboutThis?.()) {
      return;
    }

    if (this.editorContext.navigationManager?.isMarkerRendered()) {
      this.editorContext.visualizerManager?.selection.stopSelectionTracker();

      EditorManager.getInstance().removePasteOptions();

      try {
        // restore and scroll into selection if needed
        this.editorContext.navigationManager.scrollIntoSelection();

        const actionContext = new ActionContext();

        const stylableElements: any[] =
          EditorRange.splitRangeByBlocks(EditorSelectionUtils.getRange(), [
            ...EditorDOMElements.BLOCK_TEXT_ELEMENTS,
            ELEMENTS.FigureElement.TAG,
          ]) || [];

        let i;
        for (i = 0; i < stylableElements.length; i++) {
          const block = stylableElements[i].block;
          if (
            block.tagName === ELEMENTS.TrackInsertElement.TAG &&
            block.firstChild.tagName === ELEMENTS.FigureElement.TAG
          ) {
            block.firstChild.dataset.alignment = TEXT_ALIGN_PROPERTIES[params.style];
          } else {
            block.dataset.alignment = TEXT_ALIGN_PROPERTIES[params.style];
          }

          actionContext.addChangeUpdatedNode(block);

          let element;
          if (EditorDOMElements.isFigureElement(block)) {
            element = block.firstElementChild;
          } else {
            element = block;
          }

          if (
            EditorDOMElements.isImageElement(element) ||
            EditorDOMElements.isTableElement(element)
          ) {
            this.editorContext.visualizerManager
              ?.getWidgetsManager()
              ?.addWidget('resizable', block, {});
          }
        }

        this.editorContext.visualizerManager?.selection.triggerSelectionChanged();

        //@ts-expect-error
        this.editorContext.changeTracker?.saveActionChanges(actionContext);
      } finally {
        // and has no reference to it, so for now it's going directly to the EditorManager singleton
        // Change if there's a better way for this
        this.editorContext.visualizerManager?.selection.debounceStartSelectionTracker();
      }
    }
  }

  /**
   * clear selection formatations
   */
  clearFormatting(options: {
    saveMarker?: boolean;
    askUser?: boolean;
    saveChanges?: boolean;
    scrollToMarker?: boolean;
    expandWord?: boolean;
    cleanParagraphStyle?: boolean;
    rangeToHandle?: Editor.Selection.EditorRange;
  }) {
    const {
      saveMarker = true,
      askUser = true,
      saveChanges = true,
      scrollToMarker = true,
      expandWord = false, // temp disable due to jsonRange limitation
      cleanParagraphStyle = true,
    } = options;

    let rangeToHandle: Editor.Selection.EditorRange | undefined;

    // TODO: check this
    if (askUser && !this.stylesContext.askUserAboutThis?.()) {
      return;
    }

    if (this.editorContext.navigationManager?.isMarkerRendered()) {
      this.editorContext.visualizerManager?.selection.stopSelectionTracker();

      let savedRange;

      try {
        if (scrollToMarker) {
          // restore and scroll into selection if needed
          this.editorContext.navigationManager.scrollIntoSelection();
        }

        EditorManager.getInstance().removePasteOptions();

        rangeToHandle = options.rangeToHandle || EditorSelectionUtils.getRange();
        if (!rangeToHandle) {
          return;
        }

        EditorSelectionUtils.fixNonCollapsedTextSelection(
          {
            forceTextAsWrap: true,
          },
          rangeToHandle,
        );

        const actionContext = new ActionContext();

        savedRange = rangeToHandle?.saveRange();

        rangeToHandle = EditorSelectionUtils.getRangeBasedOnSavedMarkers(savedRange, false);

        const stylableRanges =
          EditorRange.splitRangeByBlocks(rangeToHandle, StylesUtils.STYLABLE_BLOCK_ELEMENTS) || [];

        let i;
        for (i = 0; i < stylableRanges.length; i++) {
          const block = stylableRanges[i].block;
          const range = stylableRanges[i].range;
          if (block instanceof BaseViewElement) {
            if (EditorDOMUtils.isClosestTextElementEditable(block)) {
              // clear text elements

              const isCollapsed = range.collapsed;

              // if range is collapsed select closest word
              if (isCollapsed && expandWord) {
                // TODO:
                this.editorContext.selection?.modifiers?.modify(range, 'move', 'word', 'backward');
                this.editorContext.selection?.modifiers?.modify(range, 'expand', 'word', 'forward');
              }

              if (
                (EditorSelectionUtils.isSelectionAtStart(block, 'start', range) &&
                  EditorSelectionUtils.isSelectionAtEnd(block, 'end', range)) ||
                (isCollapsed && stylableRanges.length === 1)
              ) {
                // if whole block is selected or selection is collapsed
                // force default style
                if (cleanParagraphStyle && block.tagName === ELEMENTS.ParagraphElement.TAG) {
                  block.setAttribute('data-style-id', ELEMENTS.ParagraphElement.ELEMENT_TYPE);
                }

                this.cleanBlockProperties(actionContext, block);
              }

              this.undoStyleAppliersFromRange(actionContext, range, undefined, false);

              actionContext.addChangeUpdatedNode(block);
            } else {
              // clear other elements
              this.cleanBlockProperties(actionContext, block);
            }
          }
        }

        if (rangeToHandle && savedRange) {
          rangeToHandle.restoreRange(savedRange);

          EditorSelectionUtils.applyRangeToSelection(rangeToHandle);
        }

        if (saveMarker) {
          this.editorContext.visualizerManager?.selection.triggerSelectionChanged();
        }

        if (saveChanges) {
          //@ts-expect-error
          this.editorContext.changeTracker.saveActionChanges(actionContext);
        }
      } catch (e) {
        Logger.captureException(e);
        if (savedRange) {
          const range = EditorSelectionUtils.getRange();
          if (range) {
            range.restoreRange(savedRange);
            EditorSelectionUtils.applyRangeToSelection(range);
          }
        }
      } finally {
        this.editorContext.visualizerManager?.selection.debounceStartSelectionTracker();
      }
    }
  }

  /**
   * @deprecated
   */
  lineSpaceCurrentSelection(spacing: Editor.Elements.SpacingProperties) {
    if (!this.stylesContext.askUserAboutThis?.()) {
      return;
    }

    if (this.editorContext.navigationManager?.isMarkerRendered()) {
      this.editorContext.visualizerManager?.selection.stopSelectionTracker();

      try {
        // restore and scroll into selection if needed
        this.editorContext.navigationManager.scrollIntoSelection();

        EditorManager.getInstance().removePasteOptions();

        // check if selection is editable
        const actionContext = new ActionContext();

        const stylableElements = EditorRange.splitRangeByBlocks() || [];

        const elementsToUpdate = [];

        let i;
        for (i = 0; i < stylableElements.length; i++) {
          const block = stylableElements[i].block;

          if (block instanceof HTMLElement) {
            if (
              block instanceof ParagraphElement &&
              EditorDOMUtils.isClosestTextElementEditable(block)
            ) {
              Object.keys(spacing).forEach((property) => {
                if (property === StylesUtils.STYLES.LINEHEIGHT) {
                  //@ts-expect-error fix types
                  block.setLineHeight?.(spacing[property]);
                } else if (property === StylesUtils.STYLES.SPACEBEFORE) {
                  //@ts-expect-error fix types
                  block.setSpaceBefore?.(spacing[property]);
                } else if (property === StylesUtils.STYLES.SPACEAFTER) {
                  //@ts-expect-error fix types
                  block.setSpaceAfter?.(spacing[property]);
                } else if (property === StylesUtils.STYLES.ASB) {
                  //@ts-expect-error fix types
                  block.setAutoSpaceBefore?.(spacing[property]);
                } else if (property === StylesUtils.STYLES.ASA) {
                  //@ts-expect-error fix types
                  block.setAutoSpaceAfter?.(spacing[property]);
                }
              });
            }
            const elements = Array.from(
              block.querySelectorAll(
                `${ELEMENTS.TemporaryComment.TAG},${ELEMENTS.CommentElement.TAG},${ELEMENTS.TrackInsertElement.TAG},${ELEMENTS.TrackDeleteElement.TAG}`,
              ),
            );
            elementsToUpdate.push(...elements);

            actionContext.addChangeUpdatedNode(block);
          }
        }

        // rerender comments and suggestions
        for (let i = 0; i < elementsToUpdate.length; i++) {
          const element = elementsToUpdate[i];
          if (
            element instanceof CommentElement ||
            element instanceof TemporaryCommentElement ||
            element instanceof TrackDeleteElement ||
            element instanceof TrackInsertElement
          ) {
            element.reRenderBackground();
          }
        }

        this.editorContext.visualizerManager?.selection.triggerSelectionChanged();

        //@ts-expect-error
        this.editorContext.changeTracker.saveActionChanges(actionContext);
      } finally {
        this.editorContext.visualizerManager?.selection.debounceStartSelectionTracker();
      }
    }
  }

  /**
   * @deprecated
   */
  setLinePaginationProperties(properties: Editor.Elements.PaginationProperties) {
    if (!this.stylesContext.askUserAboutThis?.()) {
      return;
    }

    if (this.editorContext.navigationManager?.isMarkerRendered()) {
      this.editorContext.visualizerManager?.selection.stopSelectionTracker();

      try {
        // restore and scroll into selection if needed
        this.editorContext.navigationManager.scrollIntoSelection();

        EditorManager.getInstance().removePasteOptions();

        const actionContext = new ActionContext();

        const stylableElements = EditorRange.splitRangeByBlocks() || [];

        let i;
        const length = stylableElements.length;
        for (i = 0; i < length; i++) {
          const block = stylableElements[i].block;

          if (
            block instanceof ParagraphElement &&
            EditorDOMUtils.isClosestTextElementEditable(block)
          ) {
            const propKeys = Object.keys(properties);
            for (let i = 0; i < propKeys.length; i++) {
              const prop = propKeys[i];

              switch (prop) {
                case StylesUtils.STYLES.WC:
                  //@ts-expect-error fix types
                  block.setWidowControl?.(properties[prop]);
                  break;
                case StylesUtils.STYLES.KN:
                  //@ts-expect-error fix types
                  block.setKeepNext?.(properties[prop]);
                  break;
                case StylesUtils.STYLES.KL:
                  //@ts-expect-error fix types
                  block.setKeepLines?.(properties[prop]);
                  break;
                case StylesUtils.STYLES.PBB:
                  //@ts-expect-error fix types
                  block.setPageBreakBefore?.(properties[prop]);
                  break;
                default:
                  break;
              }
            }

            actionContext.addChangeUpdatedNode(block);
          }
        }

        this.editorContext.visualizerManager?.selection.triggerSelectionChanged();
        //@ts-expect-error
        this.editorContext.changeTracker.saveActionChanges(actionContext);
      } catch (error) {
        //Logger.captureException(error);
      } finally {
        this.editorContext.visualizerManager?.selection.debounceStartSelectionTracker();
      }
    }
  }

  /**
   * @deprecated
   */
  setParagraphBackgroundColor(color: string | null) {
    // TODO: check this
    if (!this.stylesContext.askUserAboutThis?.()) {
      return;
    }

    if (this.editorContext.navigationManager?.isMarkerRendered()) {
      this.editorContext.visualizerManager?.selection.stopSelectionTracker();

      try {
        // restore and scroll into selection if needed
        this.editorContext.navigationManager.scrollIntoSelection();

        EditorManager.getInstance().removePasteOptions();

        const actionContext = new ActionContext();

        const stylableElements = EditorRange.splitRangeByBlocks() || [];

        let i;
        const length = stylableElements.length;
        for (i = 0; i < length; i++) {
          const block = stylableElements[i].block;

          if (
            block instanceof ParagraphElement &&
            EditorDOMUtils.isClosestTextElementEditable(block)
          ) {
            if (color != null) {
              if (color === 'transparent') {
                block.backgroundColor = `${false}`;
              } else {
                const hex = EditorDOMUtils.rgbToHex(color);
                if (hex) {
                  block.backgroundColor = hex.substring(1);
                } else {
                  block.backgroundColor = `${false}`;
                }
              }
            } else {
              block.backgroundColor = color;
            }
            actionContext.addChangeUpdatedNode(block);
          }
        }

        this.editorContext.visualizerManager?.selection.triggerSelectionChanged();
        //@ts-expect-error
        this.editorContext.changeTracker.saveActionChanges(actionContext);
      } catch (error) {
        // Logger.captureException(error);
      } finally {
        this.editorContext.visualizerManager?.selection.debounceStartSelectionTracker();
      }
    }
  }

  /**
   * Get current document colors.
   */
  getDocumentColors() {
    if (this.editorContext.navigationManager?.isMarkerRendered()) {
      // this.editorContext.visualizerManager?.selection.stopSelectionTracker();

      // restore and scroll into selection if needed
      // this.editorContext.navigationManager.scrollIntoSelection();

      // Get all nodes with color or backgroundcolor attribute.
      const nodesWithColors: HTMLElement[] = Array.from(
        this.editorContext.documentContainer?.querySelectorAll(
          'format-element[color], format-element[highlightcolor], paragraph-element[data-background-color], table[data-background-color], td[data-background-color]',
        ) || [],
      );

      // Add the value to colors array.
      const colors: string[] = [];
      nodesWithColors.forEach((node: HTMLElement) => {
        if (node.hasAttribute('color')) {
          colors.push(node.getAttribute('color') as string);
        }
        if (node.hasAttribute('highlightcolor')) {
          colors.push(node.getAttribute('highlightcolor') as string);
        }
        if (node.dataset.backgroundColor) {
          colors.push(node.dataset.backgroundColor);
        }
      });

      // Parse colors to object format
      const parsedColors = colors.reduce((colorsArray: any[], color: any) => {
        if (color !== false && color !== 'false' && color !== null) {
          let rgb;

          if (color.includes('rgb')) {
            rgb = color.substring(color.lastIndexOf('(') + 1, color.indexOf(')')).split(',');
          } else if (color.includes('#')) {
            rgb = hexToRgb(color);
          } else {
            rgb = hexToRgb(`#${color}`);
          }

          if (rgb != null) {
            colorsArray.push({
              r: rgb[0],
              g: rgb[1],
              b: rgb[2],
              rgb: `${rgb[0]}${rgb[1]}${rgb[2]}`,
            });
          }
        }
        return colorsArray;
      }, []);

      // Get an array with unique values
      return uniqWith(parsedColors, isEqual);
    }
    return [];
  }

  /**
   * @deprecated
   */
  indentationCurrentSelection(indentation: Editor.Elements.IndentationProperties) {
    if (!this.stylesContext.askUserAboutThis?.()) {
      return;
    }

    if (this.editorContext.navigationManager?.isMarkerRendered()) {
      this.editorContext.visualizerManager?.selection.stopSelectionTracker();

      try {
        this.editorContext.navigationManager.scrollIntoSelection();

        if (this.editorContext.selectionManager?.isSelectionInPage()) {
          EditorSelectionUtils.fixSelection();
        }

        EditorManager.getInstance().removePasteOptions();

        const actionContext = new ActionContext();

        const stylableRanges = EditorRange.splitRangeByBlocks() || [];

        for (let i = 0; i < stylableRanges.length; i++) {
          const block = stylableRanges[i].block;
          if (block instanceof HTMLElement) {
            if (
              EditorDOMUtils.isClosestTextElementEditable(block) ||
              block instanceof FigureElement
            ) {
              if (block instanceof ParagraphElement) {
                block.setIndentationProperties(indentation);
              } else {
                Object.keys(indentation).forEach((property) => {
                  if (indentation[property] != null) {
                    block.dataset[property] = `${indentation[property]}`;
                  } else {
                    block.dataset[property] = undefined;
                  }
                });
              }
              actionContext.addChangeUpdatedNode(block);
            }
          }
        }

        this.editorContext.visualizerManager?.selection.triggerSelectionChanged();

        //@ts-expect-error
        this.editorContext.changeTracker.saveActionChanges(actionContext);
      } finally {
        this.editorContext.visualizerManager?.selection.debounceStartSelectionTracker();
      }
    }
  }
}
